import SkeletonPersonalData from "@Molecule/Skeleton/Employee/DetailEmployee/PersonalData";
import DetailEmployee from "@Organism/Employee/DetailEmployee";
import { getDataDetailPersonil } from "@Services/members/detailPersonil";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const { employeeID } = route?.params;

  const dataDetailPerosnil = getDataDetailPersonil(employeeID);

  return defer({
    dataDetailPerosnil: dataDetailPerosnil,
  });
};

export default function DetailEmployeePage() {
  const { dataDetailPerosnil } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonPersonalData />}>
      <Await
        errorElement={<p>Error get data</p>}
        resolve={dataDetailPerosnil}
        children={(value) => <DetailEmployee data={value?.data} />}
      ></Await>
    </Suspense>
  );
}
