import Icon from "@Atom/Icon";
import Styles from "./style.module.scss"

export default function ExpandCard ({
    data,
    formKeys,
    title,
    subTitle,
    expandTitle,
    isExpanded,
    setIsExpanded
}){

    const handleExpanded = () => {        
        setIsExpanded(!isExpanded)
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.cardBox} onClick={handleExpanded}>
                <div className={Styles.cardWrapper}>
                    <div className={Styles.iconWrapper}>
                        <Icon icon={'document-text'} color={'#2E3192'} size={'20px'} />
                    </div>
                    <div className={Styles.cardContent}>
                        <span>{title}</span>
                        <span>{subTitle}</span>
                    </div>
                </div>
                <div className={Styles.expandIcon} onClick={handleExpanded}>
                    <Icon icon={isExpanded ? 'arrow-square-up' : 'arrow-square-down'} color={'#3E4856'} size={'24px'} />
                </div>
            </div>
            {isExpanded && (
                <div className={Styles.expandContent}>
                    {expandTitle && (
                        <div className={Styles.expandTitle}>
                            <span>{expandTitle}</span>                            
                        </div>
                    )}
                    <div className={Styles.divider}/>
                    <div className={Styles.expandForm}>
                        {
                            formKeys?.map((formValue, formIndex) => (
                                <div className={Styles.expandChildForm} key={`form_${formIndex}`} > 
                                    <div className={Styles.title}> 
                                        <span>{formValue.title}</span>
                                    </div>
                                    <div className={Styles.colon}> 
                                        <span>:</span>
                                    </div>
                                    <div className={Styles.value}>
                                        {formValue?.renderData ? (formValue?.renderData(data)) :(
                                            <span>{data[formValue?.key] ? data[formValue?.key] : "-" }</span>
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
        </div>
    );
}