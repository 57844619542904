import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import Images from "@Theme/Images";
import Skeleton from "@Atom/Skeleton";
export default function HistoryActivity({
  isHandOver = false,
  onClick = () => {},
  templateData = [
    {
      id: 1,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      frontImage: "https://placehold.co/650x253/orange/blue",
      bottomImage: "https://placehold.co/650x253",
      rightImage: "https://placehold.co/650x253",
      leftImage: "https://placehold.co/650x253",
      reportDescription: "Lorem ipsum .",
    },
  ],
  isLoading = false,
  descriptionText = (el) => {
    return ''
  }
}) {
  const { width } = useWindowSize();
  return (
    <div
      className={Styles.HistoryActivity}
      is-zoom-out={width > 1440 ? "true" : "false"}
    >
      {!isLoading
        ? templateData.map((el, idx) => (
            <div
              className={Styles.viewerDetail}
              style={{
                borderTop:
                  idx >= 1 && templateData?.length >= 1
                    ? "1px solid #dee2e7"
                    : "unset",
                borderBottom:
                  templateData.length === idx + 1
                    ? "1px solid #dee2e7"
                    : "unset",
              }}
              key={idx}
            >
              <div className={Styles.viewLeft}>
                <div className={Styles.imgProfile}>
                  <img src={el?.profilePicture} alt="image-profile" />
                </div>
                <div className={Styles.names}>
                  <span>{el?.name}</span>
                  <div className={Styles.activities}>
                    {
                      descriptionText(el) !== ''
                        ?
                        <div className={Styles.desc}>
                          {descriptionText(el)}
                        </div>
                        :
                        <div className={Styles.desc}>

                          <p>{el?.date}</p> • <p>{el?.time}</p> •<p>{el?.shift}</p>•
                          <p>{el?.activity}</p>
                        </div>
                    }

                  </div>
                </div>
              </div>
              <div className={Styles.viewRight}>
                {!isHandOver && (
                  <div
                    className={Styles.condition}
                    condition={el?.statusLocation?.toLowerCase()}
                  >
                    <span>{el?.statusLocation}</span>
                  </div>
                )}
                <Icon
                  icon={"maximize"}
                  onClick={() => onClick(el?.id)}
                  className={Styles.iconMax}
                />
              </div>
            </div>
          ))
        : [...Array(10)].map((el, idx) => (
            <div
              className={Styles.viewerDetail}
              style={{
                borderTop:
                  idx >= 1 && templateData?.length >= 1
                    ? "1px solid #dee2e7"
                    : "unset",
                borderBottom:
                  templateData.length === idx + 1
                    ? "1px solid #dee2e7"
                    : "unset",
              }}
              key={idx}
            >
              <div className={Styles.viewLeft}>
                <div className={Styles.imgProfile}>
                  <img
                    style={{ opacity: 0 }}
                    src={Images.AVA_DEFAULT}
                    alt="image-profile"
                  />
                </div>
                <div className={Styles.names}>
                  <Skeleton>
                    <span>{el?.name}</span>
                  </Skeleton>
                  <div style={{height:'4px'}}/>
                  <div className={Styles.activities}>
                    <div className={Styles.desc}>
                      <Skeleton h="16px">
                        <p>{el?.date}</p>
                      </Skeleton>{" "}
                      •{" "}
                      <Skeleton h="16px">
                        <p>{el?.time}</p>
                      </Skeleton>{" "}
                      •
                      <Skeleton h="16px">
                        <p>{el?.shift}</p>
                      </Skeleton>
                      •
                      <Skeleton h="16px">
                        <p>{el?.activity}</p>
                      </Skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.viewRight}>
                {!isHandOver && (
                  <Skeleton>
                    <div
                      className={Styles.condition}
                      condition={el?.statusLocation?.toLowerCase()}
                    >
                      <span>{el?.statusLocation}</span>
                    </div>
                  </Skeleton>
                )}
                <Skeleton w="20px" h="20px">
                  <Icon
                    icon={"maximize"}
                    // onClick={() => onClick(el?.id)}
                    className={Styles.iconMax}
                  />
                </Skeleton>
              </div>
            </div>
          ))}
    </div>
  );
}
