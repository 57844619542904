import { API } from "configs";

export function getSquadPerLocation(
  locationID = "",
  page = 1,
  limit = 10,
  searchQuery = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/group?locationID=${locationID}&page=${page}&limit=${limit}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
