import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
export function SkeletonDetailReport() {
  return (
    <div className={Styles.viewerSkeletonCards}>
      <div className={Styles.between}>
        <Skeleton w="200px" h="36px" />
        <Skeleton w="200px" h="36px" />
      </div>

      <div className={Styles.wrapCards}>
        <BoxSkeleton style={{ height: "81vh", width: "25%" }} />
        <BoxSkeleton style={{ height: "81vh", width: "100%" }} />
      </div>
    </div>
  );
}
