import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";
import ListEmployee from "@Organism/Employee/ListEmployee";
import { getAllDataActivePersonil } from "@Services/members/getDataActivPersonil";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const search = params?.get("search") || "";

  const dataActivePersonil = getAllDataActivePersonil(page, limit, search);

  return defer({
    dataActivePersonil: dataActivePersonil,
  });
};

export default function ListEmployeePage() {
  const { dataActivePersonil } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListEmployee />}>
      <Await
        resolve={dataActivePersonil}
        errorElement={<p>error get data personil aktif</p>}
      >
        {(value) => (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(212, 212, 232, 0.10)",
            }}
          >
            <ListEmployee dataActivePersonil={value} />
          </div>
        )}
      </Await>
    </Suspense>
  );
}
