import Icon from '@Atom/Icon'
import Modal from '@Atom/Modal'
import Spinner from '@Atom/Spinner'
import Map from '@Molecule/Map'
import { getAttendanceDetailsPerDate } from '@Services/attendance/getAttendanceDetailsPerDate'
import Images from '@Theme/Images'
import { fileBaseUrl } from 'configs'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function AttendanceDetailModal({ selectedAttendance, onClose = () => { } }) {
  const type = selectedAttendance?.value

  const [tab, setTab] = useState('detail')
  const [data, setData] = useState(null)

  const [expand, setExpand] = useState([false, false])

  const [isLoading, setIsLoading] = useState(false)
  const fetchData = useCallback(async () => {
    if (!selectedAttendance?.attendanceID || !selectedAttendance?.value) return
    setIsLoading(true)
    try {
      const response = await getAttendanceDetailsPerDate(selectedAttendance?.attendanceID)
      setData(response?.data?.data)
    } catch (error) {
      console.log(error, 'Error on fetching data')
    } finally {
      setIsLoading(false)
    }
  }, [selectedAttendance?.attendanceID, selectedAttendance?.value])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const [scale, setScale] = useState(100); // Nilai default skala
  const handleDecrement = () => {
    if (scale > 100) {
      setScale((prevScale) => {
        const newScale = prevScale - 10;
        return newScale >= 20 ? newScale : prevScale;
      });
    }


  };

  const handleIncrement = () => {
    setScale((prevScale) => {
      const newScale = prevScale + 10;
      return newScale <= 500 ? newScale : prevScale;
    });
  };

  const [mousePos, setMousePos] = useState({});
  const [imageCenter, setImageCenter] = useState(`center`);
  const [onImage, setOnImage] = useState(false);
  const [distanceX, setDistanceX] = useState(null);
  const [distanceY, setDistanceY] = useState(null);

  useEffect(() => {
    setMousePos({})
    setImageCenter('center')
    setScale(100)
    setDistanceX(null)
    setDistanceY(null)
  }, [selectedAttendance])

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  const activeContent = useMemo(() => {
    if (isLoading) {
      return (
        <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
          <Spinner width='42px' />
        </div>
      )
    }
    if (tab === 'detail') {
      return (
        <div className={Styles.detailContent}>
          <div className={Styles.card}>
            {
              [
                {
                  icon: 'calendar-2',
                  title: 'Tanggal',
                  value: selectedAttendance?.date ? moment(new Date(selectedAttendance?.date))?.format('LL') : '-'
                },
                {
                  icon: 'broken-clock',
                  title: 'Jam Masuk',
                  value: type === 'absence' || type === 'off' || !type ? '--:--' : moment(new Date(selectedAttendance?.startTime))?.format('HH:mm')
                },
                {
                  icon: 'clock',
                  title: 'Jam Keluar',
                  value: type === 'absence' || type === 'off' || !type ? '--:--' : moment(new Date(selectedAttendance?.endTime))?.format('HH:mm'),
                },
                {
                  icon: 'profile-add',
                  title: 'Status',
                  value: [
                    {
                      title: 'Hadir',
                      value: 'attend'
                    },
                    {
                      title: 'Sakit',
                      value: 'sick'
                    },
                    {
                      title: 'Cuti',
                      value: 'leave'
                    },
                    {
                      title: 'Izin',
                      value: 'permit'
                    },
                    {
                      title: 'Absen',
                      value: 'absence'
                    },
                  ]
                    ?.find(obj => obj?.value === selectedAttendance?.value)?.title,
                },
              ]
                ?.filter(obj => type === 'sick' ? !obj?.title?.includes('Jam') : obj)
                ?.map((p, pI) => (
                  <div key={pI}>
                    <Icon icon={p?.icon} size={16} color={'#2E3192'} />
                    <span>{p?.title}</span>
                    <p>{p?.value || '-'}</p>
                  </div>
                ))
            }
          </div>
          {
            (type === 'attend' || type === 'permit')
            &&
            <div className={Styles.location}>
              <div className={Styles.lHeader}>
                <Icon icon={'location'} size={16} color={'#919EB0'} />
                <span>Detail Lokasi</span>
                <p>Work From Office</p>
              </div>
              <div className={Styles.mapWrapper}>
                <Map
                  lat={data?.lat || -6.175110}
                  lng={data?.lng || 106.865036}
                />
              </div>
            </div>
          }

          {
            (type === 'absence'
              || type === 'sick'
              || type === 'off'
              || !selectedAttendance?.value
            )

            &&
            <div className={Styles.absenceWrapper}>

              {
                [
                  // {
                  //   title: 'Keterangan',
                  //   desc: 'Males berangkat'
                  // },
                  {
                    title: 'Foto',
                    icon: 'no-picture',
                    desc: 'Foto Tidak Tersedia'
                  },
                  {
                    title: 'Detail Lokasi',
                    icon: 'no-location',
                    desc: 'Lokasi Tidak Tersedia'
                  },

                ]
                  ?.map((d, dI) => (
                    <div key={dI}>
                      <span>{d?.title}</span>
                      <div>
                        <Icon icon={d?.icon} size={24} />
                        <span>{d?.desc}</span>
                      </div>
                    </div>
                  ))
              }
            </div>
          }
        </div>
      )
    }

    if (tab === 'equipment') {
      return (
        <div className={Styles.equipmentContent}>
          <p>
            <Icon icon={'document-stripe'} size={16} />
            <span>Kelengkapan Kerja</span>
          </p>
          <div>
            {
              data?.attendance?.equipments?.length
                ?
                data?.attendance?.equipments
                  ?.map((item, i) => (
                    <div key={i}>
                      <Icon color={'#A9B3C1'} icon={'tick-circle'} size={24} />
                      <span>{item?.name}</span>
                    </div>
                  ))
                :
                <p>
                  no equipment
                </p>
            }

          </div>
        </div>
      )
    }

    if (tab === 'report') {
      return (
        <div className={Styles.reportContent}>
          {
            [
              {
                title: 'Riwayat Penyerahan Inventaris',
                items: data?.receivedItems,
                deposit: data?.deposit?.filter(obj => obj?.remark === 'RECEIVE'),
                giver: data?.receivedBy || { name: data?.attendance?.employee?.name, profilePicture: Images.AVA_DEFAULT },
                receiver: data?.handoverBy,
              },
              {
                title: 'Riwayat Terima Inventaris',
                items: data?.handoverItems,
                deposit: data?.deposit?.filter(obj => obj?.remark === ''),
                giver: data?.handoverBy,
                receiver: data?.receivedBy || { name: data?.attendance?.employee?.name, profilePicture: Images.AVA_DEFAULT },
                withActionButton: true,
                withNotes: true
              },
            ]
              ?.map((inventory, i) => (
                <div
                  className={`${Styles.inventory} ${Styles[expand[i] ? 'expand' : '']}`}
                >
                  <div
                    className={Styles.inventoryHeader}
                    onClick={() => {
                      setExpand(prev => [...prev?.map((obj, idx) => idx === i ? !obj : obj)])
                    }}

                  >
                    <Icon color={'#2C5364'} icon={'send-2'} size={20} />
                    <span>{inventory?.title}</span>
                    <button className={Styles[expand[i] ? 'rotate' : '']}>
                      <Icon color={'#2C5364'} icon={'arrow-down'} size={20} />
                    </button>
                  </div>
                  {
                    expand[i] &&
                    <div className={Styles.inventoryContent}>
                      <div className={Styles.users}>
                        <div>
                          <img src={Images.AVA_DEFAULT} alt='' />
                          <span>{inventory?.giver?.name || '-'}</span>
                        </div>
                        <Icon icon={'arrow-back'} size={20} style={{ transform: 'rotate(180deg)' }} />
                        <div>
                          <img src={Images.AVA_DEFAULT} alt='' />
                          <span>{inventory?.receiver?.name || '-'}</span>
                        </div>
                      </div>
                      <div className={Styles.items}>
                        <div className={Styles.itemsTitle}>
                          <Icon icon={'dropbox'} size={20} />
                          <span>Inventaris Wajib</span>
                        </div>
                        {
                          inventory?.items?.length
                            ?
                            inventory?.items
                              ?.map((item, j) => (
                                <div key={j} className={Styles.itemWrapper}>
                                  <div className={Styles.item}>
                                    <Icon icon={'chevron-dot'} size={20} />
                                    <div className={Styles.itemDescription}>
                                      <span>{item?.name}</span>
                                      <p>Kondisi : {item?.condition || '-'}</p>
                                    </div>
                                    {
                                      inventory?.withActionButton
                                      &&
                                      <button className={Styles.success}>
                                        <Icon icon={'tick-circle'} size={24} />
                                      </button>
                                    }
                                  </div>
                                </div>
                              ))
                            :
                            <p>no inventory</p>

                        }
                      </div>
                      <div className={Styles.items}>
                        <div className={Styles.itemsTitle}>
                          <Icon icon={'direct-inbox'} size={20} />
                          <span>Barang Titipan Pos</span>
                        </div>
                        {
                          inventory?.deposit?.length
                            ?
                            inventory?.deposit
                              ?.map((item, j) => (
                                <div className={Styles.itemWrapper}>
                                  <div key={j} className={Styles.item}>
                                    <img src={fileBaseUrl + item?.imageURI} alt='' />
                                    <div className={Styles.itemDescription}>
                                      <span>{item?.name}</span>
                                      <p>Jumlah : {item?.amount || '-'}</p>
                                    </div>
                                    {
                                      inventory?.withActionButton
                                      &&
                                      <button className={Styles.danger}>
                                        <Icon icon={'add-circle'} size={24} style={{ transform: 'rotate(45deg)' }} />
                                      </button>
                                    }
                                  </div>
                                  {
                                    inventory?.withNotes
                                    &&
                                    <div className={Styles.notes}>
                                      <span>Catatan</span>
                                      <p>{item?.notes || '-'}</p>
                                    </div>
                                  }
                                </div>
                              ))
                            :
                            <p>no inventory</p>

                        }
                      </div>
                    </div>
                  }
                </div>
              ))
          }
        </div>
      )
    }
  }, [data?.attendance?.employee?.name, data?.attendance?.equipments, data?.deposit, data?.handoverBy, data?.handoverItems, data?.lat, data?.lng, data?.receivedBy, data?.receivedItems, expand, isLoading, selectedAttendance?.date, selectedAttendance?.endTime, selectedAttendance?.startTime, selectedAttendance?.value, tab, type])


  return (
    <Modal isOpen={selectedAttendance} onClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Detail Absen</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={10} />
          </button>
        </div>
        <div className={`${Styles.content} ${Styles[type]}`}>
          {
            (type === 'permit'
              || type === 'attend'
              || type === 'sick'
            )
            &&
            <div className={Styles.selfie}>
              {/* <img 
              src='https://media.istockphoto.com/id/1486674561/photo/young-woman-taking-a-selfie.jpg?s=612x612&w=0&k=20&c=ZbH8PakbBL8UAFhvsFSRAWXJakqz92UgnFFxRC3ucj0=' alt='' 
              style={{ transform: `scale(${scale / 100})`}}
              /> */}
              <div
                className={Styles.image}
                style={{
                  // transform: `scale(${scale / 100})`,
                  height: `calc(${scale} / 100 * 100%)`,
                  width: `calc(${scale} / 100 * 100%)`,
                  //  left: 0,
                  //  right: 0,
                  //  marginLeft: 'auto',
                  //  marginRight: 'auto',
                  backgroundImage: `url(${selectedAttendance?.image?.toLowerCase()?.includes("http")
                    ? selectedAttendance?.image?.replace(
                      "https://myworkspace",
                      "https://api1.myworkspace"
                    ) : fileBaseUrl + selectedAttendance?.image})`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: imageCenter,
                  backgroundSize: `${scale === 100 ? 'contain' : 'cover'}`,
                  position: `relative`
                }}
                onMouseDown={e => {
                  setOnImage(true)
                  setDistanceX(mousePos.x - parseInt(window.getComputedStyle(e.target).getPropertyValue("background-position").split(' ')[0].slice(0, -2)))
                  setDistanceY(mousePos.y - parseInt(window.getComputedStyle(e.target).getPropertyValue("background-position").split(' ')[1].slice(0, -2)))
                }}
                onMouseUp={() => setOnImage(false)} onMouseMove={e => {
                  if (onImage) {
                    setImageCenter(`${mousePos.x - distanceX}px ${mousePos.y - distanceY}px`)
                  }
                }}

              />

              <div className={Styles.scaler}>
                <button disabled={scale === 100} onClick={handleDecrement}>-</button>
                <span>{scale}%</span>
                <button onClick={handleIncrement}>+</button>
              </div>
            </div>
          }
          <div className={Styles.main}>
            {
              selectedAttendance?.value === 'attend'
              &&
              <div className={Styles.tabs}>
                {
                  [
                    {
                      title: 'Detail Absen',
                      value: 'detail',
                    },
                    {
                      title: 'Kelengkapan Kerja',
                      value: 'equipment',
                    },
                    {
                      title: 'Laporan Serah Terima',
                      value: 'report',
                    }
                  ]
                    ?.map(obj => (
                      <div key={obj?.value} onClick={() => setTab(obj?.value)} className={`${Styles.tab} ${Styles[tab === obj?.value ? 'active' : '']}`}>
                        <span>{obj?.title}</span>
                      </div>
                    ))
                }
              </div>

            }

            {
              activeContent
            }

          </div>
        </div>
      </div>
    </Modal>
  )
}