import { getMonths } from "@Assets/data/month";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import AttendanceDetailsLayout from "@Organism/Attendance/AttendanceDetails";
import { getAttendanceDetails } from "@Services/attendance/getAttendanceDetails";
import moment from "moment";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const attendanceDetailsLoader = (route) => {
  const url = new URL(route?.request?.url); // Parse full URL
  const params = new URLSearchParams(url.search); // Get query parameters
  const { id } = route?.params;
  const date = params.get("date") || moment(new Date()).format('YYYY-MM-DD');
  const month = getMonths('long')[new Date(date).getMonth()];
  const year = new Date(date).getFullYear();


  return defer({
    packageAttendanceDetails: getAttendanceDetails(id, month, year)
  });
};
export default function AttendanceDetailsPage() {
  const { packageAttendanceDetails } = useLoaderData()

  return (
    <Suspense fallback={<AttendanceDetailsLayout isSkeleton />}>
      <Await resolve={packageAttendanceDetails} errorElement={<AsyncErrorPage />}>
        {(packageAttendanceDetails) => (
          <AttendanceDetailsLayout
            packageAttendanceDetails={packageAttendanceDetails?.data?.data}
          />
        )}
      </Await>
    </Suspense>
  )
}