import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Tabs from "@Molecule/Tabs";
import SearchBar from "@Atom/SearchBar";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import TabHeader from "@Atom/TabHeader";
import DateListTable from "@Atom/DateListTable";
import Icon from "@Atom/Icon";
// import DropdownMoreCircle from "@Atom/DropdownMoreCircle";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileEmployee from "@Atom/ProfileEmployee";
import moment from "moment";
import useQuery from "@Hooks/useQuery";
// import { useDebounce } from "@Hooks/useDebounce";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";
import FaqImage from "@Assets/Icon/qustionLogo.svg";
import { deleteEmployee } from "@Services/employee/deleteEmployee";
import { changeStatusEmployee } from "@Services/employee/changeStatusEmployee";
import Toast from "@Atom/Toast";
import Lottie from "lottie-react";
import LoadingJSON from "@Assets/json/loading.json";
// import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import ModalAddPesonil from "@Molecule/_modal/AddMembers";
import { useDebounce } from "@Hooks/useDebounce";
import Spinner from "@Atom/Spinner";
export default function ListEmployee({
  dataActivePersonil = {
    totalData: 0,
    data: [],
  },
  dataInActivePersonil = {
    totalData: 0,
    data: [],
  },
  // isEmpty = false,
}) {
  moment.locale("id");
  const navigate = useNavigate();
  const query = useQuery();
  const limitQuery = query.get("limit") || 10;
  const searchQuery = query.get("search") || "";
  const pageQuery = query.get("page") || 1;
  const { pathname } = useLocation();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState(searchQuery);
  // const debouncedTrigger = useDebounce(searchTerm, 500);

  const [tabsActive, setTabsActive] = useState(
    +sessionStorage.getItem("tabsActive") || 0
  );

  useEffect(() => {
    sessionStorage.setItem("tabsActive", tabsActive);
  }, [tabsActive]);

  const listTabs = ["Personil Aktif", "Personil Tidak Aktif"];
  const { width } = useWindowSize();
  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setSearchTerm(searchQuery || "");
  }, [limitQuery, pageQuery, searchQuery]);

  // useEffect(() => {
  //   // Cek apakah parameter sudah sesuai dengan URL yang ada
  //   const pageDown = Math.min(dataToShow.data.length, page);

  //   const currentParams = new URLSearchParams(window.location.search);
  //   const currentLimit = currentParams.get("limit");
  //   const currentPage = currentParams.get("page");
  //   const currentSearch = currentParams.get("search");

  //   // Hanya lakukan navigate jika parameter berubah
  //   if (
  //     currentLimit !== limit.toString() ||
  //     currentPage !== pageDown.toString() ||
  //     currentSearch !== debouncedTrigger
  //   ) {
  //     navigate(
  //       `${pathname}?q=query&limit=${limit}&page=${pageDown}&search=${debouncedTrigger}`,
  //       { replace: true }
  //     );
  //   }
  // }, [
  //   limit,
  //   navigate,
  //   page,
  //   pathname,
  //   debouncedTrigger,
  //   searchTerm,
  //   dataToShow,
  // ]);

  // useEffect(() => {
  //   if (searchTerm) {
  //     const pageDown = Math.min(dataToShow.data.length, page);
  //     navigate(
  //       `${pathname}?q=query&limit=${limit}&page=${pageDown}&search=${debouncedTrigger}`,
  //       {
  //         replace: true,
  //       }
  //     );
  //   } else {
  // navigate(`${pathname}?q=query&limit=${limit}&page=${page}`, {
  //   replace: true,
  // });
  //   }
  // }, [
  //   limit,
  //   navigate,
  //   page,
  //   pathname,
  //   debouncedTrigger,
  //   searchTerm,
  //   dataToShow,
  // ]);

  const debounceQuery = useDebounce(searchTerm, 500);
  useEffect(() => {
    navigate(
      `${pathname}?q=q&page=${page}&limit=${limit}&search=${debounceQuery}`
    );
  }, [debounceQuery, limit, navigate, page, pathname]);

  useEffect(() => {
    setPage(1);
  }, [debounceQuery]);

  // eslint-disable-next-line no-unused-vars
  const [openChoose, setOopenChoose] = useState();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState();

  const [sort, setSort] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });

  const dataToShow = useCallback(() => {
    if (tabsActive === 0) {
      return dataActivePersonil;
    } else {
      return dataInActivePersonil;
    }
  }, [dataActivePersonil, dataInActivePersonil, tabsActive]);

  const sortedData = useMemo(() => {
    const sorted = [...dataToShow()?.data].sort((a, b) => {
      if (sort.column) {
        if (sort.type === "ASC") {
          return a.name?.localeCompare(b.name);
        } else {
          return b.name?.localeCompare(a.name);
        }
      }
      return 0;
    });

    return sorted;
  }, [dataToShow, sort.column, sort.type]);

  const templateDataPersonil = useMemo(() => {
    return {
      data:
        sortedData.map((l) => ({
          id: l._id,
          name: l?.user.name,
          employeeID: l?.employeeId,
          profilePicture: l?.user?.profilePicture || Images.AVA_DEFAULT,
          level: l?.jobTitle,
          startDate: l?.startDate || "-",
          endDate: l?.endDate || "-",
          squad: l?.group?.name || "-",
          workLocation: l?.location?.name || "-",
        })) || [],
      totalData: dataToShow()?.totalData,
      columns: [
        {
          clickExpand: true,
          name: "name",
          width: width > 851 ? "30%" : "200px",
          title: (
            <TabHeader
              text="Nama"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <ProfileEmployee
              name={row?.name}
              idEmployee={row.employeeID}
              image={row.profilePicture}
              onClick={() => navigate(row?.employeeID)}
            />
          ),
        },

        {
          name: "",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Tanggal Kontrak" />,
          renderData: (row) => (
            <DateListTable dateIn={row.startDate} dateOut={row.endDate} />
          ),
          hideOnMobile: true,
        },

        {
          name: "level",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Jabatan" column={"level"} />,
          renderData: (row) => <span>{row.level}</span>,
          hideOnMobile: true,
        },

        {
          name: "workLocation",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Lokasi Kerja" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.workLocation}</span>,
        },

        {
          name: "squad",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Regu" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row.squad}</span>,
        },
        // {
        //   name: "",
        //   renderData: (row) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "flex-end",
        //         alignItems: "center",
        //         gap: "16px",
        //       }}
        //     >
        //       <DropdownMoreCircle
        //         id={row?.id}
        //         setIsOpenModalArchive={() => setIsOpenModalArchive(true)}
        //         setIsModalDelete={() => setIsOpenModalDelete(true)}
        //         openChoose={openChoose}
        //         setOopenChoose={setOopenChoose}
        //         tabs={tabsActive}
        //       ></DropdownMoreCircle>
        //     </div>
        //   ),
        // },
      ],
    };
  }, [sortedData, dataToShow, width, sort, navigate]);
  const [selectedName, setSelectedName] = useState({});

  useEffect(() => {
    if (openChoose) {
      const selectedNames = templateDataPersonil.data.find(
        (el) => el?.id === openChoose
      );
      setSelectedName(selectedNames);
    }
  }, [templateDataPersonil.data, openChoose]);

  const [isOpenModalEmployee, setIsModalEmployee] = useState(false);
  const [isTransition, setIsTransition] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const handleOpenModalUpload = () => {
    setIsModalEmployee(!isOpenModalEmployee);
  };

  useEffect(() => {
    if (isOpenModalEmployee) {
      setTimeout(() => {
        setIsTransition(true);
      }, 100);
    }
  }, [isOpenModalEmployee]);

  const [isOpenModalArchive, setIsOpenModalArchive] = useState();

  const handleCloseModal = () => {
    setIsOpenModalArchive(false);
    setIsOpenModalDelete(false);
  };

  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [isChangeStatusFail, setIsChangeStatusFail] = useState(false);

  const [isDeleteEmployee, setIsDeleteEmployee] = useState(false);
  const [isDeleteEmployeeFail, setIsDeleteEmployeeFail] = useState(false);

  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const handleDeleteEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await deleteEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsDeleteEmployee(true);
        setIsLoadingHit(false);
        console.log(response?.data.message);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      console.log(error);
      setIsLoadingHit(false);
      setIsDeleteEmployeeFail(true);
    }
  };
  const handleChangeStatusEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await changeStatusEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsChangeStatus(true);
        setIsLoadingHit(false);
        console.log(response?.data.message);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      setIsChangeStatusFail(true);
      console.log(error);
      setIsLoadingHit(false);
    }
  };
  // const [file, setFile] = useState();
  // const [isOpenAddMobile, setIsOpeAddnMobile] = useState(false);
  useEffect(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
  }, [tabsActive]);
  setTimeout(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
    setIsDeleteEmployee(false);
    setIsDeleteEmployee(false);
  }, 5000);

  useEffect(() => {
    setSearchTerm("");
  }, [tabsActive]);

  // useEffect(() => {
  //   console.log("kosong", dataToShow.length === 0 && searchTerm !== "");
  // }, [dataToShow, searchTerm]);
  // console.log("isem", isEmpty);

  return (
    <>
      <Toast
        isopen={isDeleteEmployeeFail}
        color="red"
        text={`${selectedName.name} Gagal Di Hapus`}
        onClose={() => setIsDeleteEmployeeFail(false)}
      />

      <Toast
        isopen={isDeleteEmployee}
        color="green"
        text={`${selectedName.name} Berhasil Di Hapus`}
        onClose={() => setIsDeleteEmployee(false)}
      />

      <Toast
        isopen={isChangeStatusFail}
        color="red"
        text={
          tabsActive === 0
            ? `${selectedName.name} Gagal Di non aktifkan`
            : `${selectedName.name} Gagal Di Aktifkan`
        }
        onClose={() => setIsChangeStatusFail(false)}
      />

      <Toast
        isopen={isChangeStatus}
        color="green"
        text={
          tabsActive === 0
            ? `${selectedName.name} Berhasil Di non aktifkan`
            : `${selectedName.name} Berhasil Di Aktifkan`
        }
        onClose={() => setIsChangeStatus(false)}
      />

      <Modal isOpen={isLoadingHit}>
        <div className={Styles.viewLoadingCreate}>
          <Lottie
            animationData={LoadingJSON}
            loop
            className={Styles.loadingElement}
            alt="loading"
          />
        </div>
      </Modal>

      <ModalAddPesonil
        isOpen={isOpenModalEmployee}
        onClose={() => {
          setIsModalEmployee(false);
          setIsTransition(false);
        }}
        isTransition={isTransition}
      />

      {/* {dataActivePersonil.totalData === 0 &&
      dataInActivePersonil.totalData === 0 &&
      !searchQuery ? (
        <div style={{ height: "calc(100vh - 56px)" }}>
          <CustomEmptyHandlerPage
            title="Belum Ada Daftar Personil Yang Ditambahkan"
            description="Silahkan lakukan penambahan personil"
            btnText="Tambah Personil"
            onClick={handleOpenModalUpload}
            images={Images.EMPTY_PERSONAL_GUARD}
          />
        </div>
      ) : ( */}
      <>
        <Modal isOpen={!!isOpenModalDelete || isOpenModalArchive}>
          <div className={Styles.containerModal}>
            <div className={Styles.wrapperModalDropdown}>
              <div className={Styles.headerModal}>
                <img src={FaqImage} alt="faq" className={Styles.icon} />
                <h2>
                  {isOpenModalDelete
                    ? "Hapus"
                    : tabsActive === 1
                    ? "Aktifkan"
                    : "Non Aktifkan"}{" "}
                  Karyawan {selectedName?.name}
                </h2>
                <div className={Styles.closeModal} onClick={handleCloseModal}>
                  <Icon icon={"close-circle"} color={"#000"} size={12} />
                </div>
              </div>
              <div className={Styles.desc}>
                {tabsActive === 0 ? (
                  <p className={Styles.paragraph}>
                    Apakah anda yakin ingin menghapus data karyawan ini? Setelah
                    anda menghapus data ini anda tidak bisa mengembalikan data
                    yang ada.
                  </p>
                ) : (
                  <p className={Styles.paragraph}>
                    Apakah anda yakin ingin mengaktifkan kembali karyawan ini?
                  </p>
                )}
              </div>
              <div className={Styles.wrapperButton}>
                <Button
                  bgColor={"white"}
                  color={"#2C5364"}
                  text="Kembali"
                  onClick={handleCloseModal}
                />
                <Button
                  text={
                    isOpenModalDelete
                      ? "Ya, Hapus"
                      : tabsActive === 0
                      ? "Ya, Arsipkan"
                      : "Ya, Aktifkan"
                  }
                  onClick={
                    isOpenModalDelete
                      ? () => handleDeleteEmployee()
                      : () => handleChangeStatusEmployee()
                  }
                />
              </div>
            </div>
          </div>
        </Modal>
        <div style={{ width: "100%" }}>
          <section
            className={Styles.containerListEmployee}
            style={{ height: "100%" }}
          >
            <div className={Styles.wrapperListEmployee}>
              <div className={Styles.headerList} style={{ height: "100%" }}>
                <div className={Styles.header}>
                  <h2 className={Styles.headerText}>Daftar Personil</h2>
                </div>
                <Tabs
                  setIsActive={setTabsActive}
                  isActive={tabsActive}
                  listTabs={listTabs}
                  style={{ marginBottom: "12px" }}
                />
                <div className={Styles.fieldTable}>
                  <div className={Styles.filtering}>
                    <div style={{ width: "295px" }}>
                      <SearchBar
                        setValue={setSearchTerm}
                        value={searchTerm}
                        size="m"
                      />
                    </div>
                    {/* {width >= 851 && (
                      <div className={Styles.filter}>
                        <span>Filter</span>
                        <Icon
                          icon={"setting-4"}
                          color={"#2C5364"}
                          size={"16"}
                          className={Styles.iconFilter}
                        />
                      </div>
                    )} */}
                    <div className={Styles.wrapFilterMobile}>
                      {/* {width <= 851 && (
                        <div className={Styles.filter}>
                          <span>Filter</span>
                          <Icon
                            icon={"setting-4"}
                            color={"#2C5364"}
                            size={"16"}
                            className={Styles.iconFilter}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  {(limit !== +limitQuery ||
                    page !== +pageQuery ||
                    (searchTerm.length > 1 &&
                      debounceQuery !== searchQuery)) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        zIndex: "9999",
                      }}
                    >
                      <Spinner width="50px" />
                    </div>
                  )}

                  <Table
                    className={Styles.table}
                    data={templateDataPersonil.data}
                    columns={templateDataPersonil.columns}
                    page={page}
                    limit={limit}
                    totalData={templateDataPersonil.totalData || 0}
                    setLimit={setLimit}
                    setPage={setPage}
                    totalPage={
                      Math.ceil(templateDataPersonil.totalData / limit) || 0
                    }
                    withPagination
                    withNumbering
                    widthNumber={"20px"}
                    expandComponent={(row) => (
                      <div className={`${Styles.wrapperExpand} expandedRow`}>
                        <div className={Styles.list}>
                          <span className={Styles.textLeft}>
                            Tanggal Kontrak
                          </span>
                          <DateListTable
                            dateIn={row?.startDate}
                            dateOut={row?.endDate}
                          />
                        </div>
                        <div className={Styles.list}>
                          <span className={Styles.textLeft}>Jabatan</span>
                          <span className={Styles.textRight}>{row.level}</span>
                        </div>
                        <div className={Styles.list}>
                          <span className={Styles.textLeft}>Lokasi Kerja</span>
                          <span className={Styles.textRight}>
                            {row.workLocation}
                          </span>
                        </div>
                        <div className={Styles.list}>
                          <span className={Styles.textLeft}>Regu</span>
                          <span className={Styles.textRight}>{row.squad}</span>
                        </div>
                        {/* <span
                          className={Styles.detail}
                          onClick={() => navigate(`detail/${row.id}`)}
                        >
                          Lihat Detail
                        </span> */}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
      {/* )} */}
    </>
  );
}
