import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import { fileBaseUrl } from "configs";
export default function ModalAddPatrolPoint({
  isOpen = false,
  fileRef = null,
  onSubmit = () => {},
  onClose = () => {},
  isFilled = false,
  inputLocation = {
    image: "",
    name: "",
  },
  setInputLocation = () => {},
  selectedLocation,
  setSelectedLocation,
  isTransition = false,
}) {
  const handleChangeInput = (name, value, id) => {
    if (id) {
      setInputLocation((prev) => ({
        ...prev,
        fieldPosInventory: prev.fieldPosInventory.map((el) =>
          id === el.id
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      }));
    } else {
      setInputLocation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [prevImage, setPrevImage] = useState();
  useEffect(() => {
    setPrevImage(inputLocation.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        style={{
          position: "relative",
          height: "99%",
          width: isFilled ? "100%" : "unset",
        }}
      >
        <div
          className={Styles.modalAddWorkLocation}
          is_filled={(isFilled && isOpen)?.toString()}
          is_transition={isTransition.toString()}
        >
          <div className={Styles.headerModal}>
            <strong>
              {isFilled ? "Pengaturan Lokasi" : "Tambah Titik Patroli 1"}
            </strong>
            <Icon
              icon={"close-circle"}
              onClick={onClose}
              className={Styles.closeCircle}
            />
          </div>
          <div className={Styles.uploadLocation}>
            <strong>
              Foto Titik Patroli <span>*</span>{" "}
            </strong>
            <div className={Styles.chooseFile}>
              <input
                type="file"
                hidden
                accept=".jpg, .jpeg, .png"
                ref={fileRef}
                onChange={(e) => handleChangeInput("image", e.target.files[0])}
              />

              {inputLocation.image ? (
                <img
                  alt="image-location"
                  onClick={() => fileRef.current.click()}
                  src={
                    typeof inputLocation.image === "object" &&
                    isFilled &&
                    prevImage !== inputLocation.image
                      ? URL.createObjectURL(inputLocation.image)
                      : isFilled && typeof inputLocation.image === "string"
                      ? fileBaseUrl + inputLocation.image
                      : URL.createObjectURL(inputLocation.image)
                  }
                />
              ) : (
                <>
                  <input
                    type="file"
                    hidden
                    accept=".jpg, .jpeg, .png"
                    ref={fileRef}
                    onChange={(e) =>
                      handleChangeInput("image", e.target.files[0])
                    }
                  />
                  <Icon
                    icon={"document-upload"}
                    color={"#2C5364"}
                    size={"48"}
                  />
                  <div className={Styles.viewerCol}>
                    <span>Unggah Titik Lokasi Patroli</span>
                    <p>Unggah file dengan format .JPEG, PNG & JPG</p>
                  </div>
                  <Button
                    text="Browse File"
                    onClick={() => fileRef.current.click()}
                  />
                </>
              )}
            </div>
          </div>
          <div className={Styles.inputField}>
            <InputField
              setValue={(v) => handleChangeInput("name", v)}
              textLabel="Deskripsi Titik Patroli"
              isLabel
              isRequired
              placeholder="Contoh: Lantai 1 Lobby Utama"
              value={inputLocation.name}
            />
            <div className={Styles.containerMap}>
              <Map
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                isAutoComplete
                coordinate={{
                  latitude: +selectedLocation?.latitude,
                  longitude: +selectedLocation?.longitude,
                }}
              />
            </div>
          </div>
          <div className={Styles.footerModal}>
            <Button text="Simpan Titik Patroli" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
