import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import DetailGuardPos from "@Organism/WorkLocation/GuardPos/Detail";
import { getDetailGuardPost } from "@Services/guardPost/getDetailGuardPost";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = ({ params }) => {
  const { guardPostID } = params;
  const fetchDataDetailGuardPost = getDetailGuardPost(guardPostID);

  return defer({
    getData: fetchDataDetailGuardPost,
  });
};

export default function DetailGuardPosPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailGuardPos />}>
      <Await resolve={getData} errorElement={<h1>Error get data !</h1>}>
        {(value) => <DetailGuardPos data={value?.data} />}
      </Await>
    </Suspense>
  );
}
