import { getMonths } from "@Assets/data/month";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import AttendanceListLayout from "@Organism/Attendance/AttendanceList";
import { SkeletonListMembersLocation } from "@Organism/Skeletons/WorkLocations/ListMembers/List";
import { getDailyAttendance } from "@Services/attendance/getDailyAttendance";
import { getMonthlyAttendance } from "@Services/attendance/getMonthlyAttendance";
import { getAllLocationsPerClient } from "@Services/workLocation/getAllLocationsPerClient";
import moment from "moment";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const attendanceListLoader = (route) => {
  const url = new URL(route?.request?.url); // Parse full URL
  const params = new URLSearchParams(url.search); // Get query parameters

  // const type = params.get("type") ? [] : [];
  const limit = params.get("limit") || 10;
  const page = params.get("page") || 1;
  const search = params.get("search") || '';
  const tab = params.get("tab") || 'daily';
  const date = params.get("date") || moment(new Date()).format('YYYY-MM-DD');
  const month = getMonths('long')[new Date(date).getMonth()];
  const year = new Date(date).getFullYear();
  const types = params.get("type") ? (
    params?.get('type')?.includes('-')
      ?
      params?.get('type')?.split('-')
      :
      [params?.get('type')]
  ) : [];
  const positions = params.get("position") ? (
    params?.get('position')?.includes('-')
      ?
      params?.get('position')?.split('-')
      :
      [params?.get('position')]
  ) : [];
  const locations = params.get("location") ? (
    params?.get('location')?.includes('-')
      ?
      params?.get('location')?.split('-')
      :
      [params?.get('location')]
  ) : [];
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));


  return defer({
    packageAttendanceList:
      tab !== 'monthly'
        ? getDailyAttendance(page, limit, search, date, types, positions, locations)
        : getMonthlyAttendance(page, limit, search, month, year),
    packageLocations: getAllLocationsPerClient(CLIENT_KEY?.code)
  });
};

export default function AttendanceListPage() {
  const { packageAttendanceList, packageLocations } = useLoaderData()

  return (
    <Suspense
      fallback={<SkeletonListMembersLocation />}
    >
      <Await resolve={packageAttendanceList} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
        {(packageAttendanceList) => (
          <Suspense
            fallback={<SkeletonListMembersLocation />}
          >
            <Await resolve={packageLocations} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
              {(packageLocations) => (
                <AttendanceListLayout
                  packageAttendanceList={packageAttendanceList?.data?.data}
                  packageLocations={packageLocations?.data?.data}
                />

              )}
            </Await>
          </Suspense>
        )}
      </Await>

    </Suspense>
  )
}