import { API } from "configs";

export function getReportPerMonth(
  type = "activity",
  page = 1,
  limit = 10,
  status = "SIAGA"
) {
  return new Promise(async (resolve, reject) => {
    try {
      const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
      const { data } = await API.get(
        `/clientreport/monthly/${type}?page=${page}&limit=${limit}&clientCode=${CLIENT_KEY.code}&status=${status}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
