import Button from "@Atom/Button";
import DatePicker from "@Atom/DatePicker";
import Icon from "@Atom/Icon";
import { useState } from "react";
import { downloadExcel } from "../downloadAttendance";
import Styles from "./style.module.scss";
// import useOutsideClick from "@Hooks/useOutsideClick";
export default function ButtonDownloadAttendance({ month, year, date, ref, onClose = () => { } }) {
  const listText = ["Bulanan", "Custom Date"];
  const [optionModal, setOptionModal] = useState("Bulanan");
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEendDate, setInputEndDate] = useState("");
  // const { id: locationID } = useParams();

  //   const refBox = useRef(null);
  //   const isOutsideClick = useOutsideClick(refBox);

  //   useEffect(() => {
  //     if (isOutsideClick && !!optionModal) {
  //       setOptionModal(false);
  //     }
  //   }, [isOutsideClick, optionModal]);

  // const [selectedDate, setSelectedDate] = useState(date);


  return (
    <>
      {optionModal === listText[1] ? (
        // <div className={Styles.selectDate}>
        //   <SelectDate
        //     setValue={(newVal) => {
        //       setSelectedDate(newVal)

        //     }}
        //     onClickDate={(newVal) => {
        //       downloadExcel({
        //         downloadType: optionModal,
        //         date: moment(newVal).format("YYYY-MM-DD"),
        //       })
        //       onClose()
        //     }}
        //     value={selectedDate}
        //     autoSave
        //   />
        // </div>

        <div className={Styles.datesWrapper} ref={ref}>
          <div className={Styles.header}>
            <div>
              <Icon icon={"document-upload"} color={"#2C5364"} size={"20"} />
              <strong>Pilih Tanggal</strong>
            </div>
            <Icon
              icon={"close-circle"}
              color={"#3E4856"}
              className={Styles.closeIcon}
              size={"14"}
              onClick={() => setOptionModal(false)}
            />
          </div>
          <div className={Styles.inputField}>
            <DatePicker
              placeholder="Tanggal Awal"
              setValue={setInputStartDate}
              value={inputStartDate}
            />
            <DatePicker
              placeholder="Tanggal Akhir"
              setValue={setInputEndDate}
              value={inputEendDate}
            />
          </div>
          <div className={Styles.btnDownload}>
            <Button
              text="Download"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() =>{
                downloadExcel({
                  startDate: inputStartDate,
                  endDate: inputEendDate,
                  downloadType: optionModal,
                })
                onClose()
              }
              }
            />
          </div>
        </div>
      ) : (
        <div className={Styles.wrapperBtnDownloadReport} ref={ref}>
          <ul>
            {listText.map((l, idx) => (
              <li
                onClick={() =>
                  idx !== 1
                    ? downloadExcel({
                      month,
                      year,
                      date,
                      downloadType: optionModal,
                    })
                    : setOptionModal(l)
                }
                key={idx}
                style={{fontStyle: idx === 1? "italic" : "normal" }}
              >
                {l}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
