import FileViewerModal from "@Atom/FileViewerModal";
import Icon from "@Atom/Icon";
import TagStatus from "@Atom/TagStatus";
import { get35DaysInMonth } from "helpers/get35DaysInMonth";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailAssetEmployee from "./AssetEmployee";
import DetailAttendanceEmployee from "./AttendanceEmployee";
import DetailCareerEmployee from "./CareerEmployee";
import DetailFinanceEmployee from "./FinanceEmployee";
import { DetailPersonalEmployee } from "./PersonalEmployee";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";

export default function DetailEmployee({ data }) {
  const sideMenu = useMemo(() => {
    return [
      {
        title: "Informasi Karyawan",
        icon: "profile-circle",
      },
      {
        title: "Kehadiran",
        icon: "calendar",
      },
      {
        title: "Karir",
        icon: "ranking",
      },
      {
        title: "Keuangan",
        icon: "money-4",
      },
    ];
  }, []);

  const navigate = useNavigate();

  const startDate = useMemo(() => {
    return new Date();
  }, []);

  const attendanceData = useMemo(() => {
    const defaultData = [
      {
        date: "2024-08-01",
        value: "attend",
        checkIn: new Date(),
        checkOut: new Date(),
      },
      {
        date: "2024-08-02",
        value: "sick",
      },
      {
        date: "2024-08-03",
        value: "leave",
      },
      {
        date: "2024-08-04",
        value: "permit",
      },
      {
        date: "2024-08-05",
        value: "absence",
      },
      {
        date: "2024-08-06",
        value: "sick",
      },
      {
        date: "2024-08-07",
        value: "leave",
      },
      {
        date: "2024-08-08",
        value: "permit",
      },
      {
        date: "2024-08-09",
        value: "absence",
      },
      {
        date: "2024-08-10",
        value: "sick",
      },
      {
        date: "2024-08-11",
        value: "leave",
      },
      {
        date: "2024-08-12",
        value: "permit",
      },
      {
        date: "2024-08-13",
        value: "absence",
      },
    ];
    // return defaultData

    const allDays = get35DaysInMonth(new Date(startDate));

    // const dateInMonths = getDaysInMonth(new Date(startDate)?.getMonth(), new Date(startDate)?.getFullYear(), 'dates')
    return allDays?.map((obj) => {
      return {
        date: moment(obj)?.format("YYYY-MM-DD"),
        value:
          defaultData?.find(
            (d) => d?.date === moment(obj)?.format("YYYY-MM-DD")
          )?.value || "",
      };
    });
    // return []
  }, [startDate]);

  const activeSideMenu = +sessionStorage.getItem("activeMenuDetailEmployee");
  const [selectedSideMenu, setSelectedSideMenu] = useState(activeSideMenu || 1);

  useEffect(() => {
    sessionStorage.setItem("activeMenuDetailEmployee", selectedSideMenu);
  }, [selectedSideMenu]);

  const [viewFile, setViewFile] = useState(null);
  const [showFile, setShowFile] = useState(false);

  const sideMenuContent = useMemo(() => {
    switch (selectedSideMenu) {
      case 1:
        return <DetailPersonalEmployee data={data?.user} />;
      case 2:
        return <DetailAttendanceEmployee attendances={attendanceData} />;
      case 3:
        return (
          <DetailCareerEmployee
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      case 4:
        return (
          <DetailFinanceEmployee
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      case 5:
        return (
          <DetailAssetEmployee
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      default:
        return <DetailPersonalEmployee />;
    }
  }, [attendanceData, data?.user, selectedSideMenu, showFile, viewFile]);

  return (
    <div className={Styles.containerDetailEmployee}>
      <div className={Styles.wrapperDetailEmployee}>
        <div className={Styles.nameDetail}>
          <Icon
            icon={"arrow-down"}
            color={"black"}
            size={"20"}
            className={Styles.arrowBack}
            onClick={() => navigate("/list-members")}
          />
          <span>{capitalizeEachWord(data?.access.name)}</span>
        </div>
        <div className={Styles.mainContent}>
          <section className={Styles.sideMenu}>
            <div className={Styles.profileName}>
              <img
                src={Images.AVA_DEFAULT}
                alt="profile-picture"
                className={Styles.imageProfile}
              />
              <div className={Styles.nameAndPosition}>
                <span className={Styles.name}>
                  {capitalizeEachWord(data?.access.name)}
                </span>
                <span className={Styles.position}>
                  {data?.access.client.role}
                </span>
                <div>
                  <Icon icon={"building"} color={"#2C5364"} size={"16"} />
                  <span>{capitalizeEachWord(data?.access.client.name)}</span>
                </div>
              </div>
              <TagStatus
                text={
                  data?.user?.status?.toLowerCase() === "aktif"
                    ? "aktif"
                    : "Tidak Aktif"
                }
                color={
                  data?.user?.status?.toLowerCase() === "aktif"
                    ? "green"
                    : "red"
                }
              />
            </div>
            <div className={Styles.lineSeparator}></div>
            <div className={Styles.menus}>
              {sideMenu?.map((item, idx) => (
                <Fragment key={idx}>
                  <div
                    className={Styles.listMenu}
                    is-active={selectedSideMenu === idx + 1 ? "true" : "false"}
                    onClick={() => {
                      setSelectedSideMenu(idx + 1);
                    }}
                  >
                    <Icon icon={item.icon} className={Styles.icon} />
                    <span>{item.title}</span>
                  </div>
                  {idx !== sideMenu?.length - 1 && (
                    <div className={Styles.lineDashed}></div>
                  )}
                </Fragment>
              ))}
            </div>
          </section>

          <div className={Styles.divider}></div>

          {sideMenuContent}
        </div>
        {showFile && (
          <FileViewerModal
            isOpen={showFile}
            onClose={() => {
              setShowFile(false);
              setViewFile({});
            }}
            fileType={viewFile?.type}
            fileUrl={viewFile?.url}
            fileName={viewFile?.url?.split("/")?.pop()}
            hideOnMobile
          />
        )}
      </div>
    </div>
  );
}
