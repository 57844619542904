import React from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "configs";
export default function ModalActivity({
  isOpen = false,
  isTransition = false,
  showDataModalActivity = [
    {
      id: 1,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      activity: "Aktifitas 1",
      statusLocation: "Aman",
      reportDescription:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
    },
  ],
  setOpenModalActivity = () => {},
  onClose = () => {},
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={Styles.wrapModalActivities}
        is-open={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          {showDataModalActivity?.map((el, idx) => (
            <span key={idx}>Detail Aktifitas • {el.activity}</span>
          ))}
          <Icon
            icon={"close-circle"}
            size={"14"}
            className={Styles.icon}
            onClick={() => setOpenModalActivity(false)}
          />
        </div>
        {showDataModalActivity?.map((el, idx) => (
          <div className={Styles.mainContentModal} key={idx}>
            <div className={Styles.status}>
              <span>Status Lokasi</span>
              <p>{el?.statusLocation}</p>
            </div>
            <div className={Styles.status}>
              <span>Deskripsi Laporan</span>
              <p>{el?.reportDescription}</p>
            </div>
            {el?.images?.map((i, idxImage) => (
              <div className={Styles.status} key={idxImage}>
                <span>
                  Foto Lokasi Tampak{" "}
                  {idxImage === 0
                    ? "Depan"
                    : idxImage === 1
                    ? "Belakang"
                    : idxImage === 2
                    ? "Kanan"
                    : "Kiri"}
                </span>
                <img src={fileBaseUrl + i?.URI} alt="image-location" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
}
