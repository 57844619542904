/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import ExpandCard from "@Atom/ExpandCard";
import Icon from "@Atom/Icon";
import TabHeader from "@Atom/TabHeader";
import Table from "@Molecule/Table";
import Tabs from "@Molecule/Tabs";
import { useMemo, useState } from "react";
import Styles from "./style.module.scss";

const defaultDataCareer = {
  contract: [
    {
      year: "2024",
      type: "PKWT",
      startDate: "03 Jan 2023",
      endDate: "03 Jan 2024",
      leader: {
        name: "Kujou Sara",
        position: "Account Officer",
        profilePicture: "",
      },
      documentUrl: "https://files.testfile.org/PDF/10MB-TESTFILE.ORG.pdf",
    },
    {
      year: "2024",
      type: "PKM",
      startDate: "03 Jan 2023",
      endDate: "03 Jan 2024",
      leader: {
        name: "Yae Sakura",
        position: "Account Officer",
        profilePicture:
          "https://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpg",
      },
      documentUrl:
        "https://c2c.permataindonesia.com/contracts/1643593157243.pdf",
    },
  ],
  resign: {
    name: "Yon Safira Minangkabau",
    id: "20122222",
    position: "Recruitment Officer",
    division: "Finance",
    phoneNumber: "+628118127383",
    address: "RT.11/RW.1, Mampang Prapatan, South Jakarta City, Jakarta",
    joinDate: "17 Mei 2024",
    resignDate: "25 Mei 2025",
    resignReason: "Lorem ipsum dolor sit amet",
    resignDocumentUrl: "https://files.testfile.org/PDF/10MB-TESTFILE.ORG.pdf",
    createdAt: "03 Maret 2024",
  },
};

export default function DetailCareerEmployee({
  viewFile,
  setViewFile,
  showFile,
  setShowFile,
}) {
  const [data, setData] = useState(defaultDataCareer);
  const [isActiveTabs, setIsActiveTabs] = useState(0);
  const [isChecked, setIsChecked] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState({});
  const [totalPage, setTotalPage] = useState(1);

  const tabs = ["Kontrak", "Resign"];

  const handleViewFile = (data, key) => {
    if (data[key]) {
      setViewFile({
        type: "pdf",
        url: data[key],
      });
      setShowFile(true);
    }
  };

  const template = useMemo(() => {
    return {
      data: data?.contract?.map((value, idx) => {
        return {
          id: idx,
          year: value?.year,
          type: value?.type,
          startDate: value?.startDate,
          endDate: value?.endDate,
          directSupervisor: value?.leader,
          documentUrl: value?.documentUrl,
        };
      }),
      columns: [
        {
          name: "year",
          width: "10%",
          title: (
            <TabHeader
              text="Tahun"
              column={"year"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div
              onClick={() => {
                handleViewFile(row, "documentUrl");
              }}
            >
              <span>{row?.year}</span>
            </div>
          ),
        },
        {
          name: "type",
          width: "10%",
          title: (
            <TabHeader
              text="Tipe"
              column={"type"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
        },
        {
          name: "startDate",
          width: "20%",
          title: (
            <TabHeader
              text="Tgl Kontrak"
              column={"contractDate"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div
              className={Styles.contractDateWrapper}
              onClick={() => {
                handleViewFile(row, "documentUrl");
              }}
            >
              <div className={Styles.contractDateChild}>
                <Icon icon="arrow-right" size={"12px"} color={"#22970F"} />
                <span>{row?.startDate}</span>
              </div>
              <div className={Styles.contractDateChild}>
                <Icon icon="arrow-left" size={"12px"} color={"#D42701"} />
                <span>{row?.endDate}</span>
              </div>
            </div>
          ),
        },
        {
          name: "directSupervisor",
          width: "50%",
          title: (
            <TabHeader
              text="Atasan Langsung"
              column={"directSupervisor"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.supervisorWrapper}>
              <Avatar
                imgUrl={row?.directSupervisor?.profilePicture}
                initial={row?.directSupervisor?.name[0]}
              />
              <div className={Styles.supervisorContent}>
                <span>{row?.directSupervisor?.name}</span>
                <span>{row?.directSupervisor?.position}</span>
              </div>
            </div>
          ),
        },
        {
          name: "action",
          width: "10%",
          title: <TabHeader text="Aksi" />,
          renderData: (row) => (
            <div className={Styles.actionMenu}>
              <Icon
                icon={"edit-2"}
                color={"#2E3192"}
                size={"20"}
                className={Styles.icon}
              />
              <Icon
                icon={"trash"}
                color={"red"}
                size={"20"}
                className={Styles.icon}
              />
            </div>
          ),
          hideOnMobile: true,
        },
      ],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contract, sort]);

  const templateCard = useMemo(() => {
    return {
      data: data?.resign,
      form: [
        {
          title: "Nama",
          key: "name",
        },
        {
          title: "NIK",
          key: "id",
        },
        {
          title: "Posisi",
          key: "position",
        },
        {
          title: "Divisi",
          key: "division",
        },
        {
          title: "Nomor Telfon",
          key: "phoneNumber",
        },
        {
          title: "Alamat",
          key: "address",
        },
        {
          title: "Tanggal Join",
          key: "joinDate",
        },
        {
          title: "Tanggal Resign",
          key: "resignDate",
        },
        {
          title: "Alasan Resign",
          key: "resignReason",
        },
        {
          title: "Surat Resign",
          key: "resignDocumentUrl",
          renderData: (value) => (
            <div
              className={Styles.resignWrapper}
              onClick={() => {
                handleViewFile(value, "resignDocumentUrl");
              }}
            >
              {value["resignDocumentUrl"] ? (
                <div className={Styles.resignDoc}>
                  <Icon icon="document-text" size="20px" color="#2E3192" />
                  <span>{value["resignDocumentUrl"]}</span>
                </div>
              ) : (
                <span>{"-"}</span>
              )}
            </div>
          ),
        },
      ],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.resign]);

  return (
    <div className={Styles.content}>
      <div className={Styles.wrapperTabs}>
        <Tabs
          listTabs={tabs}
          isActive={isActiveTabs}
          setIsActive={setIsActiveTabs}
        />
        {isActiveTabs == 0 && (
          <div>
            <Button
              text="Unduh"
              isOutlined
              icon={"download"}
              isLeftIcon
              isDisabled={isChecked?.length > 0 ? false : true}
            />
          </div>
        )}
      </div>
      {isActiveTabs == 0 ? (
        <div style={{ height: "90%" }}>
          <Table
            className={Styles.tableRadius}
            data={template.data}
            columns={template.columns}
            page={page}
            limit={limit}
            totalData={data?.length}
            totalPage={totalPage}
            setLimit={setLimit}
            setPage={setPage}
            withPagination
            withCheckbox
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            expandComponent={(row) => (
              <>
                <span>Atasan Langsung</span>
                <div className={Styles.supervisorWrapper}>
                  <Avatar
                    imgUrl={row?.directSupervisor?.profilePicture}
                    initial={row?.directSupervisor?.name[0]}
                  />
                  <div className={Styles.supervisorContent}>
                    <span>{row?.directSupervisor?.name}</span>
                    <span>{row?.directSupervisor?.position}</span>
                  </div>
                </div>
              </>
            )}
          />
        </div>
      ) : (
        <div>
          <ExpandCard
            data={data?.resign}
            formKeys={templateCard?.form}
            title={"Data Resign"}
            subTitle={data?.resign?.createdAt}
            expandTitle={"Formulir Resign"}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
      )}
    </div>
  );
}
