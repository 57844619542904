import Button from '@Atom/Button';
import CustomDatePicker from '@Atom/CustomDatePicker';
import Icon from '@Atom/Icon';
import TabHeader from '@Atom/TabHeader';
import useWindowSize from '@Hooks/useWindowSize';
import SetLeaveModal from '@Molecule/_modal/SetLeaveModal';
import BigCalendar from '@Molecule/BigCalendar';
import Table from '@Molecule/Table';
import Tabs from '@Molecule/Tabs';
import moment from 'moment';
import { useMemo, useState } from 'react';
import Styles from './style.module.scss';

export default function DetailAttendanceEmployee({ attendances = [] }) {
  const [isActiveTabs, setIsActiveTabs] = useState(0);

  const { width } = useWindowSize();


  const [valueDate, setValueDate] = useState(new Date())
  const [valueDate2, setValueDate2] = useState(new Date())

  const [showSetLeave, setShowSetLeave] = useState(false)

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);


  const tabs = useMemo(() => {
    return ['Kehadiran', 'Cuti'];
  }, []);

  const leavesData = useMemo(() => {
    return [
      {
        id: 1,
        applyDate: moment(new Date())?.format('ll'),
        leaveType: 'Cuti tahunan',
        startDate: moment(new Date())?.format('ll'),
        endDate: moment(new Date())?.format('ll'),
        description: 'Ada keperluan keluarga mendadak yang tidak bisa diewatkan sehingga mau tidak mau harus cuti bos'
      }
    ]
  }, [])

  const leaveColumns = useMemo(() => {
    return [
      {
        name: "applyDate",
        width: width > 768 ? "141px" : "141px",
        title: <TabHeader text="Tgl Pengajuan" />,
      },
      {
        name: "leaveType",
        width: width > 768 ? "170px" : "170px",
        title: <TabHeader text="Jenis Cuti" />,
      },
      {
        name: "leaveDate",
        width: width > 768 ? "130px" : "130px",
        title: <TabHeader text="Tanggal Cuti" />,
        renderData: (row) => (
          <div className={Styles.leaveDate}>
            <p>
              <Icon icon={'arrow-right'} size={12} color={'#22970F'} />
              <span>{row?.startDate}</span>
            </p>
            <p>
              <Icon icon={'arrow-left'} size={12} color={'#D42701'} />
              <span>{row?.endDate}</span>
            </p>
          </div>
        )
      },
      {
        name: "description",
        width: width > 768 ? "354px" : "200px",
        title: <TabHeader text="Deskripsi" />,
        renderData: (row) => (
          <div className={Styles.desc}>
            {row?.description}
          </div>
        )
      },
    ]
  }, [width])

  const content = useMemo(() => {
    switch (isActiveTabs) {
      case 1:
        return (
          <div className={Styles.leaves}>
            <div className={Styles.indicators}>
              {
                [
                  {
                    icon: 'pie-chart',
                    iconColor: '#EED202',
                    title: 'Jatah Cuti',
                    value: 12,
                    extraValue: 1
                  },
                  {
                    icon: 'calendar-check',
                    iconColor: '#0244EE',
                    title: 'Sisa Cuti Karyawan',
                    value: 9,
                    extraValue: 0
                  }
                ]
                  ?.map((i, iI) => (
                    <div key={iI} className={Styles.indicator}>
                      <Icon icon={i?.icon} size={20} color={i?.iconColor} />
                      <span>{i?.title}</span>
                      <div className={Styles.iValue}>
                        <span>{i?.value}</span>
                        {i?.extraValue > 0
                          &&
                          <div>
                            + {i?.extraValue}
                          </div>
                        }
                      </div>
                    </div>
                  ))
              }
            </div>
            <div className={Styles.tableWrapper}>
              <Table
                data={leavesData}
                columns={leaveColumns}
                page={page}
                limit={limit}
                totalData={leavesData.length}
                totalPage={3}
                setLimit={setLimit}
                setPage={setPage}
                withPagination
                withNumbering

              />
            </div>
          </div>
        )

      default:
        return (
          <div className={Styles.attendances}>
            <div className={Styles.types}>
              {
                [
                  {
                    title: 'Hadir',
                    bgColor: '#F4FFF2',
                    borderColor: '#22970F',
                    value: '15'
                  },
                  {
                    title: 'Sakit',
                    bgColor: '#FEEDFF',
                    borderColor: '#DB2BE5',
                    value: '01'
                  },
                  {
                    title: 'Cuti',
                    bgColor: '#FFFCE6',
                    borderColor: '#EED202',
                    value: '01'
                  },
                  {
                    title: 'Izin',
                    bgColor: '#F0F0FF',
                    borderColor: '#2E3192',
                    value: '01'
                  },
                  {
                    title: 'Absen',
                    bgColor: '#FFF4F2',
                    borderColor: '#D42701',
                    value: '01'
                  },
                ]
                  ?.map((t, tI) => (
                    <div key={tI} style={{ backgroundColor: t?.bgColor, borderColor: t?.borderColor }}>
                      <span>{t?.title}</span>
                      <span>{t?.value}</span>
                    </div>
                  ))
              }
            </div>
      
              <BigCalendar attendances={attendances} />
          </div>
        )
    }
  }, [attendances, isActiveTabs, leaveColumns, leavesData, limit, page])

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapperTabs}>
        <Tabs listTabs={tabs} isActive={isActiveTabs} setIsActive={setIsActiveTabs} />
        {
          isActiveTabs === 0
            ?
            <CustomDatePicker type='month-year' value={valueDate} setValue={setValueDate}  right='0'/>
            :
            <div className={Styles.rightTabs}>
              <CustomDatePicker type='year-only' value={valueDate2} setValue={setValueDate2} />
              <Button
                text='Atur Cuti'
                onClick={() => setShowSetLeave(true)}
              />
            </div>
        }

      </div>
      {content}
      <SetLeaveModal
        show={showSetLeave}
        onClose={() => setShowSetLeave(false)}
      />

    </div>

  )
}