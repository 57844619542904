import { SkeletonListScheduleLocation } from "@Organism/Skeletons/WorkLocations/WorkSchedules/List";
import ListLocationWorkSchedule from "@Organism/WorkLocation/WorkSchedule/List";
import { getDataSchedulePerYear } from "@Services/workSchedule/getDataSchedulePerYear";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const year = params?.get("year") || new Date().getFullYear();
  const { id: locationID } = route?.params;

  const getDataSchedule = getDataSchedulePerYear(year, locationID);

  return defer({
    getDataSchedule: getDataSchedule,
  });
};

export default function ListLocationWorkSchedulePage() {
  const { getDataSchedule } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListScheduleLocation />}>
      <Await errorElement={<h1>Error get data !</h1>} resolve={getDataSchedule}>
        {(data) => <ListLocationWorkSchedule data={data} />}
      </Await>
    </Suspense>
  );
}
