import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import Button from "@Atom/Button";
import { fileBaseUrl } from "configs";
import ErrorBoundary from "@Molecule/ErrorBoundary";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Skeleton, { CircleSkeleton } from "@Atom/Skeleton";
import { getReportPerMonth } from "@Services/report/getReportPermonth";
import Images from "@Theme/Images";
import moment from "moment";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
export default function ModalStatusReportWithFetcher({
  onClose = () => { },
  textHead = "Daftar Laporan Dengan Status Waspada",
  isTransition = false,
  isStandBy = false,
  type = '',
  status = ''
}) {
  const { width } = useWindowSize();
  const [showLocation, setShowLocation] = useState(false);

  const [isLoadingFirst, setIsLoadingFirst] = useState(true)
  const [templateData, setTemplateData] = useState([]);

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const [error, setError] = useState({
    code: '',
    message: ''
  })
  const observerRef = useRef(null); // Ref for the intersection observer target


  const initialFetch = useCallback(async () => {
    try {
      const response = await getReportPerMonth(type, page, 10, status)
      setTemplateData(response?.data)
      setTotalPage(response?.totalPage)
      setIsLoadingFirst(false)
    } catch (err) {
      setIsLoadingFirst(false)
      setError({
        code: err?.response?.data?.statusCode || 'ERROR',
        message: err.response?.data?.message || 'Terjadi kesalahan saat melakukan pencarian data'
      })
    }
  }, [page, status, type])

  useEffect(() => {
    initialFetch()
  }, [initialFetch])

  const fetchMore = useCallback(async () => {
    if (isLoadingMore || page > totalPage) return;

    setIsLoadingMore(true);
    try {
      const response = await getReportPerMonth(type, page + 1, 10, status)
      setTemplateData((prevData) => [...prevData, ...response?.data]);
      setTotalPage(response?.totalPage)

      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, page, status, totalPage, type]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoadingMore) {
          fetchMore()
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [fetchMore, isLoadingMore]);


  const showDataModalActivity = useMemo(() => {
    return (
      templateData
        ?.map(obj => {

          return {
            id: obj?.employee?.ID,
            name: obj?.employee?.name,
            profilePicture: obj?.employee?.profilePicture ? fileBaseUrl + obj?.employee?.profilePicture : Images.AVA_DEFAULT,
            date: moment(obj?.checkpoint?.submittedAt)?.format("LLLL"),
            time: moment(obj?.checkpoint?.submittedAt)?.format("HH:mm") + ', WIB',
            shift:
              obj?.shift ? (`${obj.shift === "P" ? "Pagi" : obj.shift === "S" ? "Sore" : "Malam"}`) :
                "No Shift",
            activity: capitalizeEachWord(obj?.checkpoint?.title),
            statusLocation: capitalizeEachWord(obj?.checkpoint?.status),
            images:obj?.checkpoint?.images,
            reportDescription: obj?.checkpoint?.description,
            location: capitalizeEachWord(obj?.checkpoint?.detail?.name)
          }
        })
      // || [
      //   {
      //     id: 1,
      //     name: "Anton Sinaga",
      //     personil: "Nama Personil",
      //     titleIncident: "Pencurian Kabel Gudang",
      //     shift: "Pagi",
      //     activity: "Aktifitas 1",
      //     statusLocation: "Waspada",
      //     frontImage: "https://placehold.co/650x253/orange/blue",
      //     bottomImage: "https://placehold.co/650x253",
      //     rightImage: "https://placehold.co/650x253",
      //     leftImage: "https://placehold.co/650x253",
      //     reportDescription:
      //       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
      //   },
      // ]
    );
  }, [templateData]);

  return (
    <Modal isOpen>
      <div
        className={Styles.ModalStatusReport}
        is-open={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          <div>
            <Icon
              icon={isStandBy ? "Information" : "information-alert"}
              size={"20"}
              //   className={Styles.icon}
              onClick={onClose}
              color={isStandBy ? "#E78B00" : "#D42701"}
            />
            <span>{textHead}</span>
          </div>
          <Icon
            icon={"close-circle"}
            size={"14"}
            className={Styles.icon}
            onClick={onClose}
          />
        </div>
        <div className={Styles.mainContainModalStatus}>
          {!!showLocation ? (
            <>
              {showDataModalActivity
                ?.filter((dataToDetail) => dataToDetail.id === showLocation)
                ?.map((el, idx) => (
                  <div className={Styles.mainContentModal} key={idx}>
                    <Button
                      text="Kembali"
                      isAddButton
                      icon={"arrow-back"}
                      isLeftIcon
                      colorIcon={"#2C5364"}
                      style={{
                        background: "white",
                        color: "#2C5364",
                        padding: "4px 8px",
                      }}
                      onClick={() => setShowLocation(false)}
                    />
                    <div className={Styles.status}>
                      <span>Personil</span>
                      <p>{el?.name}</p>
                    </div>
                    {/* <div className={Styles.status}>
                    <span>Judul Kejadian</span>
                    <p>{el?.titleIncident}</p>
                  </div> */}
                    <div className={Styles.status}>
                      <span>Status Lokasi</span>
                      <p>{el?.statusLocation}</p>
                    </div>
                    <div className={Styles.status}>
                      <span>Deskripsi Laporan</span>
                      <p>{el?.reportDescription}</p>
                    </div>
                    {el?.images?.map((i, index) => (
                      <div className={Styles.status} key={index}>
                        <span>
                          Foto Lokasi Tampak{" "}
                          {index === 0
                            ? "Depan"
                            : idx === 1
                              ? "Belakang"
                              : index === 2
                                ? "Kanan"
                                : "Kiri"}
                        </span>
                        <img src={fileBaseUrl + i?.URI} alt="image-location" />
                      </div>
                    ))}
                  </div>
                ))}
            </>
          ) : (
            error?.message
              ?
              <ErrorBoundary
                message={error?.message}
                code={error?.code}
              />
              :
              (
                !isLoadingFirst
                  ?
                  <div
                    className={Styles.HistoryActivity}
                    is-zoom-out={width > 1440 ? "true" : "false"}
                  >
                    {
                      !showDataModalActivity?.length
                        ?
                        <div className={Styles.emptyWrapper}>
                          <TableEmptyHandler />
                        </div>
                        :
                        showDataModalActivity?.map((el, idxF) => (
                          <div
                            className={Styles.viewerDetail}
                            style={{
                              borderTop:
                                idxF >= 1 && templateData?.length >= 1
                                  ? "1px solid #dee2e7"
                                  : "unset",
                              borderBottom:
                                templateData.length === idxF + 1
                                  ? "1px solid #dee2e7"
                                  : "unset",
                            }}
                            key={idxF}
                          >
                            <div className={Styles.viewLeft}>
                              <div className={Styles.imgProfile}>
                                <img src={el.profilePicture} alt="image-profile" />
                              </div>
                              <div className={Styles.names}>
                                <span>{el.name}</span>
                                <div className={Styles.activities}>
                                  <div className={Styles.desc}>
                                    <p>{el.date}</p> • <p>{el.time}</p> •<p>{el.shift}</p>
                                    •<p>{el.activity}</p>
                                  </div>
                                </div>
                                <div className={Styles.location}>
                                  <Icon icon={"building"} color={"#2C5364"} size={"16"} />
                                  <span>{el.location}</span>
                                </div>
                              </div>
                            </div>
                            <div className={Styles.viewRight}>
                              <div
                                className={Styles.condition}
                                condition={el?.statusLocation?.toLowerCase()}
                              >
                                <span>{el?.statusLocation}</span>
                              </div>
                              <Icon
                                icon={"maximize"}
                                onClick={() => setShowLocation(el?.id)}
                                className={Styles.iconMax}
                              />
                            </div>
                          </div>
                        ))}
                  </div>
                  :
                  <div className={Styles.HistoryActivity}>
                    {[...Array(10)].map((_, idxF) => (
                      <div
                        className={Styles.viewerDetail}
                        style={{
                          borderTop:
                            idxF >= 1
                              ? "1px solid #dee2e7"
                              : "unset",
                          borderBottom:
                            10 === idxF + 1
                              ? "1px solid #dee2e7"
                              : "unset",
                        }}
                        key={idxF}
                      >
                        <div className={Styles.viewLeft}>
                          <div className={Styles.imgProfile}>
                            <CircleSkeleton w="40px" h="40px" />
                          </div>
                          <div className={Styles.names}>
                            <Skeleton h="24px" w="150px" />
                            <div className={Styles.activities} style={{ paddingBlock: '4px' }}>
                              <Skeleton h="16px" w="350px" />
                            </div>
                            <div className={Styles.location}>
                              <Skeleton h="16px" w="100px" />
                            </div>
                          </div>
                        </div>
                        <div className={Styles.viewRight}>
                          <Skeleton h="20px" w="84px" />
                          <Skeleton h="20px" w="20px" />
                        </div>
                      </div>
                    ))}
                  </div>
              )
          )}
          {
            isLoadingMore
            &&
            <div className={Styles.HistoryActivity}>
              {[...Array(10)].map((_, idxF) => (
                <div
                  className={Styles.viewerDetail}
                  style={{
                    borderTop:
                      idxF >= 1
                        ? "1px solid #dee2e7"
                        : "unset",
                    borderBottom:
                      10 === idxF + 1
                        ? "1px solid #dee2e7"
                        : "unset",
                  }}
                  key={idxF}
                >
                  <div className={Styles.viewLeft}>
                    <div className={Styles.imgProfile}>
                      <CircleSkeleton w="40px" h="40px" />
                    </div>
                    <div className={Styles.names}>
                      <Skeleton h="24px" w="150px" />
                      <div className={Styles.activities} style={{ paddingBlock: '4px' }}>
                        <Skeleton h="16px" w="350px" />
                      </div>
                      <div className={Styles.location}>
                        <Skeleton h="16px" w="100px" />
                      </div>
                    </div>
                  </div>
                  <div className={Styles.viewRight}>
                    <Skeleton h="20px" w="84px" />
                    <Skeleton h="20px" w="20px" />
                  </div>
                </div>
              ))}
            </div>
          }
          {
            page < totalPage
            && !isLoadingFirst
            && !isLoadingMore
            &&
            (
              <div
                ref={observerRef}
                style={{ height: "20px", backgroundColor: "transparent" }}
              />
            )
          }
        </div>
      </div>
    </Modal>
  );
}
