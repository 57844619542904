import { API } from "configs";

export function getAllMembersPlotting(
  locationID,
  monthString,
  year,
  type = [],
  searchQuery = ""
) {
  return new Promise(async (resolve, reject) => {
    const types = type?.map((t) => `type=${t}`).join("&");
    try {
      const { data } = await API.get(
        `/workschedule/members?locationID=${locationID}&month=${monthString}&year=${year}&${types}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
