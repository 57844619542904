import { downloadCustomAttendance, downloadMonthlyAttendance } from "@Services/attendance/downloadMonthlyAttendance";
import ExcelJS from "exceljs";

export const downloadExcel = async ({
  month,
  date,
  startDate,
  endDate,
  year,
  downloadType,
}) => {
  try {
    const response = downloadType === 'Bulanan' ? await downloadMonthlyAttendance(month, year) : await downloadCustomAttendance(date, startDate, endDate);
    if (!response.status) {
      throw new Error("Failed to fetch data");
    }
    const data = await response?.data?.data;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // 3. Tambahkan Header
      worksheet.columns = [
        { header: "No", key: "id", width: 5 },
        { header: "Nama", key: "name", width: 20 },
        { header: "NRK", key: "employeeID", width: 25 },
        { header: "Jumlah Hari Kerja", key: "totalWorkDays", width: 25 },
        { header: "Kehadiran", key: "present", width: 25 },
        { header: "Revisi", key: "revision", width: 25 },
        { header: "Cuti", key: "leave", width: 25 },
        { header: "Absen", key: "absent", width: 25 },
        { header: "Izin", key: "permit", width: 25 },
        { header: "Terlambat", key: "late", width: 25 },
      ];
   

    // 4. Tambahkan Data
    data.forEach((item, idx) => {
      worksheet.addRow({
        id: idx + 1,
        ...item,
      });
    });
 

    // 5. Styling Header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "#2C5364" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
    });

    // 6. Simpan file Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Laporan Absensi ${downloadType}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the report:", error);
  }
};
