import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ExcelIcon from "@Assets/Images/Excel-Icon.png";
import SearchBar from "@Atom/SearchBar";
import useOutsideClick from "@Hooks/useOutsideClick";
import Checkbox from "@Atom/Checkbox";
import AvatarImages from "@Assets/Images/ava-default.png";
import moment from "moment";
import { useDebounce } from "@Hooks/useDebounce";
import ModalUploadWorkSchedule from "@Atom/ModalUploadEmployee";
import CustomDatePicker from "@Atom/CustomDatePicker";
export default function DetailLocationWorkSchedule({ data }) {
  const emptyData = data?.data?.length === 0 || !data?.data;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const listDataPersonil = useMemo(() => {
    return data?.data?.map((p) => ({
      id: p?.employeeID,
      profilePicture: AvatarImages,
      name: p?.name,
      position: p?.role,
      plotting: p?.plottings,
      isNonShift: false,
    }));
  }, [data?.data]);

  const { month: selectedMonth, id: locationID } = useParams();
  const selectedMonthNumber = +sessionStorage.getItem("selectedMonthNumber");

  const selectedYear = +sessionStorage.getItem("yearScheduleWorkLocation");
  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");
  // const listShift = ["Pagi", "Siang", "Malam", "Off"];

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedTrigger = useDebounce(searchQuery, 500);

  const [openShift, setOpenShift] = useState(false);
  const refChooseShift = useRef();
  const outsideClick = useOutsideClick(refChooseShift);

  useEffect(() => {
    if (openShift && outsideClick) {
      setOpenShift(false);
    }
  }, [openShift, outsideClick]);

  // const [selectedShift, setSelectedShift] = useState({});
  // const handleSetShift = (date, id, shift) => {
  //   setSelectedShift((prev) => ({
  //     ...prev,
  //     [date + id]: shift,
  //   }));
  //   setOpenShift(null);
  // };

  const [file, setFile] = useState();
  const [isOpenModalEmployee, setIsModalEmployee] = useState(false);
  const handleOpenModalUpload = () => {
    setIsModalEmployee(!isOpenModalEmployee);
  };

  const [isOpenDropdownFilter, setIsOpenDropdownFilter] = useState(false);

  const listFilterTeam = ["Semua", "Danru", "Staff", "Non Shift"];

  const listDays = ["MIN", "SEN", "SEL", "RAB", "KAM", "JUM", "SAB"];

  const firstDate = new Date(selectedYear, selectedMonthNumber).getDate();

  const lastDate = new Date(selectedYear, selectedMonthNumber + 1, 0).getDate();

  const [isOpenSelectDate, setIsOpenSelectDate] = useState(false);

  let datesLength = [];
  for (let index = firstDate; index <= lastDate; index++) {
    datesLength.push(index);
  }

  const listDateDays = datesLength?.map((date) => {
    const dayOfWeek = new Date(
      selectedYear,
      selectedMonthNumber,
      date
    ).getDay();

    return {
      date: date.toString(), // Date as string
      day: listDays[dayOfWeek], // Day name from listDays array
    };
  });

  const blocks = [];
  let currentBlock = [];

  listDateDays.forEach((dateObj) => {
    currentBlock.push({
      ...dateObj,

      datePlotting: data?.data?.map(
        (pl) =>
          pl.plottings.find(
            (perDay) => moment(perDay.plottedAt).format("D") === dateObj.date
          )?.plotAlias || "-"
      ),
    });

    if (dateObj.day === "MIN") {
      blocks.push(currentBlock);
      currentBlock = []; // Reset blok
    }
  });

  if (currentBlock.length > 0) {
    blocks.push(currentBlock); // Menambahkan blok terakhir jika ada sisa hari
  }

  const [isCheckedFilterSquad, setIsCheckedFilterSquad] = useState(["Semua"]);

  const handleCheckedFilterMembers = (value) => {
    setIsCheckedFilterSquad((prev) => {
      let updatedFiltered;

      if (prev.includes(value)) {
        updatedFiltered = prev.filter((el) => el !== value);
      } else {
        updatedFiltered = [...prev, value];
      }
      return updatedFiltered;
    });
  };

  useEffect(() => {
    if (isCheckedFilterSquad?.length === 0) {
      setIsCheckedFilterSquad(["Semua"]);
    }
  }, [isCheckedFilterSquad?.length]);

  const filterRef = useRef(null);

  const outsideClickFilter = useOutsideClick(filterRef);

  useEffect(() => {
    if (isOpenDropdownFilter && outsideClickFilter) {
      setIsOpenDropdownFilter(false);
    }
  }, [isOpenDropdownFilter, outsideClickFilter]);

  const backPath = pathname.split("/").slice(0, -2).join("/");

  useEffect(() => {
    const filtersType = isCheckedFilterSquad
      ?.map((f) => `type=${f === "Semua" ? "" : f?.toUpperCase()}`)
      ?.join("&");

    navigate(`${pathname}?q=q&${filtersType}&search=${debouncedTrigger}`);
  }, [debouncedTrigger, navigate, pathname, isCheckedFilterSquad]);

  const monthYear = `${selectedMonth} ${selectedYear}`;
  const now = moment();

  const defaultDate = moment(monthYear, "MMMM YYYY")
    .date(now.date())
    .hour(now.hour())
    .minute(now.minute())
    .second(now.second());

  const [value, setValue] = useState(defaultDate);

  const getValueMonthYear = useMemo(() => {
    const yearAndMonth = new Date(value);
    const year = yearAndMonth.getFullYear();
    moment.locale("en");
    const month = moment(yearAndMonth).format("MMMM") || selectedMonth;
    return {
      year,
      month,
    };
  }, [selectedMonth, value]);

  useEffect(() => {
    if (getValueMonthYear.month !== "Invalid date") {
      if (
        getValueMonthYear.month !== selectedMonth ||
        getValueMonthYear.year !== +selectedYear
      ) {
        setIsOpenSelectDate(false);
        sessionStorage.setItem(
          "yearScheduleWorkLocation",
          getValueMonthYear.year
        );
        navigate(
          `/work-location/detail/${locationID}/attendance/detail/${getValueMonthYear.month}`,
          {
            replace: true,
          }
        );
      }
    }
  }, [
    getValueMonthYear.month,
    getValueMonthYear.year,
    navigate,
    pathname,
    selectedMonth,
    locationID,
    selectedYear,
  ]);

  // REFETCH

  return (
    <div
      className={` ${Styles.wrapperDetailWorkSchedule} ${
        emptyData && Styles.isEmpty
      }`}
    >
      <header className={`${Styles.header}`}>
        <div>
          <div className={Styles.back} onClick={() => navigate(backPath)}>
            <Icon
              icon={"arrow-chevron-left"}
              className={Styles.back}
              size={"20"}
            />
            <h3>{nameLocation}</h3>
          </div>
          <div className={Styles.wrapDateTime}>
            <div>-</div>
            <div
              className={Styles.date}
              onClick={() => setIsOpenSelectDate(!isOpenSelectDate)}
            >
              <h3>
                {selectedMonth} {selectedYear}
              </h3>
              <CustomDatePicker
                type="month-year"
                isForModal
                isOpen={isOpenSelectDate}
                setValue={setValue}
                value={value}
                autoSave
                isRange
              >
                <Icon
                  icon={"calendar"}
                  className={Styles.calendarIcon}
                  size={"20"}
                  color={"#919EB0"}
                />
              </CustomDatePicker>
            </div>
          </div>
          <div className={Styles.status}>
            <span>Belum Lengkap</span>
          </div>
        </div>
        <div
          className={Styles.uploadSchedule}
          onClick={() => setIsModalEmployee(true)}
        >
          <img src={ExcelIcon} alt="excel-icon" />
          <span>Upload Jadwal</span>
        </div>
        <ModalUploadWorkSchedule
          isOpen={isOpenModalEmployee}
          onClose={handleOpenModalUpload}
          setFile={setFile}
          file={file}
          month={selectedMonthNumber}
          year={selectedYear}
          monthString={selectedMonth}
          dataEmployee={data?.data}
          getValueMonthYear={getValueMonthYear}
        />
      </header>
      <div
        className={` ${data?.data?.length < 10 && Styles.viewPortStyles}  ${
          Styles.mainContainDetailWorkSchedule
        }`}
      >
        <div className={Styles.listPersonil}>
          <div className={Styles.textListPersonil}>
            <div>
              <h3>Daftar Anggota</h3>
              <Icon
                icon={"setting-3"}
                size={"24"}
                onClick={() => setIsOpenDropdownFilter(!isOpenDropdownFilter)}
                className={Styles.icon}
              />
              {isOpenDropdownFilter && (
                <div className={Styles.viewDropdownFiltered} ref={filterRef}>
                  {listFilterTeam?.map((l, idx) => (
                    <div key={idx}>
                      <Checkbox
                        setIsChecked={() => handleCheckedFilterMembers(l)}
                        isChecked={isCheckedFilterSquad?.includes(l)}
                      />
                      <span>{l}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <SearchBar size="s" setValue={setSearchQuery} value={searchQuery} />
          </div>
          <div className={Styles.personil}>
            {listDataPersonil?.map((el, idx) => (
              <div className={Styles.between} key={idx}>
                <div>
                  <img src={el.profilePicture} alt="profile-picture" />
                  <div className={Styles.names}>
                    <span>{el.name}</span>
                    <p>{el.position}</p>
                  </div>
                </div>
                {el.isNonShift && (
                  <div className={Styles.nonShift}>
                    <span>Non shift</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={Styles.schedules}>
          <div className={Styles.month}>
            <span>
              {selectedMonth} {selectedYear}
            </span>
          </div>
          <div className={Styles.wrapperDateTwoWeeks}>
            {blocks.map((datas, idx) => (
              <div
                className={Styles.week}
                key={idx}
                style={{
                  borderRight:
                    idx < blocks.length ? "1px solid #dee2e7" : "unset",
                }}
              >
                <div>
                  {datas.map((el, idx) => (
                    <div className={Styles.days} key={idx}>
                      <span>{el.day}</span>
                      <span>{el.date}</span>
                    </div>
                  ))}
                </div>

                <div className={Styles.selectedDay}>
                  {listDataPersonil?.map((_, idxLp) => (
                    <div className={Styles.bullets} key={idxLp}>
                      {datas.map((dateAndDay, idx) => (
                        <div key={idx}>
                          <span>{dateAndDay.datePlotting[idxLp]}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
