import { API } from "configs";

export function getReportDetails(
  type = "activities",
  page = 1,
  limit = 10,
  locationID,
  date,
  status = [],
  employeeID = [],
  shift = "P"
) {
  return new Promise(async (resolve, reject) => {
    try {
      const statuses = status.map((s) => `&status=${s}`).join("");
      const employeesID = employeeID.map((id) => `&employeeID=${id}`).join("");

      const { data } = await API.get(
        `/locationreport/${type}?page=${page}&limit=${limit}&locationID=${locationID}&date=${date}${statuses}${employeesID}&shift=${shift}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
