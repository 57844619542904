import ListSkeletonInfoLocation from "@Organism/Skeletons/WorkLocations/Information";
import ListLocation from "@Organism/WorkLocation/ListLocation/List";
import { getAllInfoWorkLocation } from "@Services/workLocation/getAllInfoWorkLocation";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const fetchAllDataInfoWorkLocation = getAllInfoWorkLocation(page, limit);

  return defer({
    getData: fetchAllDataInfoWorkLocation,
  });
};

export default function ListLocationPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<ListSkeletonInfoLocation />}>
      <Await errorElement={<h1>Error get data !</h1>} resolve={getData}>
        {(data) => <ListLocation data={data?.data} />}
      </Await>
    </Suspense>
  );
}
