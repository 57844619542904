import { API } from "configs";

export function getDataReportDownloadExcel({
  type = "activity",
  month,
  date,
  startDate,
  endDate,
  year,
  locationID,
  downloadType = "Harian",
  // default daily
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        downloadType === "Custom Date"
          ? `/locationreport/download/${type}?locationID=${locationID}&type=Custom Date&start=${startDate}&end=${endDate}`
          : downloadType === "Bulanan"
          ? `/locationreport/download/${type}?locationID=${locationID}&type=Bulanan&month=${month}&year=${year}`
          : `/locationreport/download/${type}?locationID=${locationID}&type=Harian&date=${date}`,

        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
