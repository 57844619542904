import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import { useMemo, useState } from 'react'
import Checkbox from '@Atom/Checkbox'
import SearchBar from '@Atom/SearchBar'

export default function AttendanceFilterSlider({
  show,
  onClose = () => { },
  types = [],
  setTypes = () => { },
  positions = [],
  setPositions = () => { },
  locations = [],
  setLocations = () => { },
  onSave = () => { },
  onReset = () => { },
  locationList = []
}) {
  const [search, setSearch] = useState('')
  const attendanceOptions = useMemo(() => {
    return [
      {
        title: 'Hadir',
        value: 'PRESENT'
      },
      {
        title: 'Tidak Hadir',
        value: 'NOT PRESENT',
      },
      {
        title: 'Off',
        value: 'OFF'
      }
    ]
  }, [])
  const positionOptions = useMemo(() => {
    return [
      {
        title: 'Dandru',
        value: 'DANDRU'
      },
      {
        title: 'Staff',
        value: 'STAFF'
      },
    ]
  }, [])

  const locationOptions = useMemo(() => {
    return locationList?.map(obj => {
      return {
        id: obj?._id,
        name: obj?.name
      }
    })
  }, [locationList])

  const filteredLocations = useMemo(() => {
    return locationOptions?.filter(obj => obj?.name?.toLocaleLowerCase()?.includes(search?.toLowerCase()))
  }, [locationOptions, search])

  return (
    <Modal isOpen={show} onClose={onClose} isSlider>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Filter</h3>
          <button>
            <Icon icon={'close-circle'} size={12} />
          </button>
        </div>
        <div className={Styles.content}>
          {
            [
              {
                title: 'Kehadiran',
                options: attendanceOptions,
                checkedValues: types,
                setCheckedValues: setTypes
              },
              {
                title: 'Jabatan',
                options: positionOptions,
                checkedValues: positions,
                setCheckedValues: setPositions
              }
            ]
              ?.map((section, i) => (
                <Section title={section?.title} key={i}>
                  {
                    section?.options?.map((option, j) => {
                      const found = section?.checkedValues.find(obj => obj === option?.value)
                      return (
                        <Option
                          key={j}
                          text={option?.title}
                          isChecked={found ? true : false}
                          onClick={() => {
                            if (found) {
                              section?.setCheckedValues(prev => prev.filter(obj => obj !== option?.value))
                            } else {
                              section?.setCheckedValues(prev => [...prev, option?.value])
                            }
                          }}
                        />
                      )
                    })
                  }
                </Section>
              ))
          }
          <Section title='Lokasi'>
            <SearchBar
              size='m'
              placeholder='Cari lokasi'
              value={search}
              setValue={setSearch}
            />
            <div className=''>
              {filteredLocations?.map((location, k) => {
                const found = locations.find(obj => obj?.toLowerCase() === location?.name?.toLowerCase())
                return (
                  <Option
                    key={k}
                    text={location?.name}
                    isChecked={found ? true : false}
                    onClick={() => {
                      if (found) {
                        setLocations(prev => prev.filter(obj => obj?.toLowerCase() !== location?.name?.toLowerCase()))
                      } else {
                        setLocations(prev => [...prev, location?.name])
                      }
                    }}
                  />
                )
              })}
            </div>
          </Section>
        </div>
        <div className={Styles.actions}>
          <button onClick={() => {
            onReset()
            onClose()
          }}>Reset</button>
          <button onClick={() => {
            onSave()
            onClose()
          }}>Terapkan</button>
        </div>
      </div>
    </Modal>
  )
}

const Section = ({
  title = '',
  children,
  className = ''
}) => {

  return (
    <div className={`${Styles.section}`}>
      {title
        &&
        <span className={Styles.sectionTitle}>{title}</span>
      }
      <div className={className}>
        {children}
      </div>
    </div>
  )
}

const Option = ({
  isChecked = false,
  onClick = () => { },
  text = '',
}) => {
  return (
    <div className={Styles.option} onClick={onClick}>
      <Checkbox isChecked={isChecked} />
      <span>{text}</span>
    </div>
  )
}