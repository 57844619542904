import { API } from "configs";

export function uploadScheduleByExcel(payload = "", locaTionID) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(
        `/plotting/upload-excel/${locaTionID}`,
        payload,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
