import Modal from "@Atom/Modal";
import Styles from "./style.module.scss"
import Icon from "@Atom/Icon";
import TagStatus from "@Atom/TagStatus";
import { rupiahFormat } from "helpers/rupiahFormat";
import Button from "@Atom/Button";

export default function ModalEpayslip({
    data,
    isOpen,
    onClose,
}){
    const handleStatusType = (status) => {
        if (status){
            switch(status){
                case "Berjalan":
                    return "blue"
                case "Belum Terbayar":
                    return "red"
                case "Hold":
                    return "yellow"
                case "Terbayar":
                    return "green"
                case "Lunas":
                    return "green"
                default : return ""
            }
        } 
        return ""
    }

    return (
        <Modal isOpen={isOpen}>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <span>{`E-Pay Slip ${data?.date}`}</span>
                    <div className={Styles.iconClose} onClick={onClose}>
                        <Icon
                            size={"16px"}
                            icon={"close-circle"}
                        />
                    </div>
                </div>
                <div className={Styles.body}>
                    {data?.details?.map((mainValue, mainIdx) => (
                        <div className={Styles.cardBox} key={`card_${mainIdx}`}>
                            <div className={Styles.title}>
                                <span>{mainValue?.title}</span>
                            </div>
                            <div className={Styles.contentWrapper}>
                                {mainValue?.list?.map((value, idx) => (
                                    <div className={Styles.childContent}>
                                        <span>{value?.title}</span>
                                        <span>{rupiahFormat(value?.value)}</span>
                                    </div>
                                ))}
                                <div className={Styles.divider}/>
                                <div className={Styles.totalWrapper}>
                                    <span>{"Total Income"}</span>
                                    <span>{rupiahFormat(mainValue?.total)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className={Styles.takeHomePayWrapper}>
                        <div className={Styles.takeHomePay}>
                            <span>{"Total Take Home Pay"}</span>
                            <span>{rupiahFormat(data?.takeHomePay)}</span>
                        </div>
                        <div className={Styles.status}>
                            <span>{"Status"}</span>
                            <TagStatus text={data?.status} color={handleStatusType(data?.status)} />
                        </div>
                    </div>
                </div>
                <div className={Styles.footer}>
                    <Button
                        icon={"download"}
                        isLeftIcon
                        text="Unduh"
                    />
                </div>
            </div>
        </Modal>
    )
}