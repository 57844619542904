import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import ReportActivity from "@Organism/Report/Activity";
import ReportPatrol from "@Organism/Report/Patrol";
import { getReportCounter } from "@Services/report/getReportCounter";
import { getReportPerMonth } from "@Services/report/getReportPermonth";
import { getAllInfoWorkLocation } from "@Services/workLocation/getAllInfoWorkLocation";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const activityReportLoader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;

  const dataAllLocations = getAllInfoWorkLocation(page, limit);
  const dataCounter = getReportCounter("ACTIVITY");
  const dataStandByPerMonthly = getReportPerMonth("activity", 1, 10, "SIAGA");
  const dataAlertPerMonthly = getReportPerMonth("activity", 1, 10, "WASPADA");

  return defer({
    getAllDataReportActivity: Promise.all([
      dataAllLocations,
      dataCounter,
      dataStandByPerMonthly,
      dataAlertPerMonthly,
    ]).then(
      ([
        dataAllLocations,
        dataCounter,
        dataStandByPerMonthly,
        dataAlertPerMonthly,
      ]) => ({
        dataAllLocations,
        dataCounter,
        dataStandByPerMonthly,
        dataAlertPerMonthly,
      })
    ),
  });
};

export default function ReportActivityPage() {
  const { getAllDataReportActivity } = useLoaderData();

  return (
    <Suspense fallback={<ReportPatrol isLoadingCounter isLoadingLocations />}>
      <Await
        resolve={getAllDataReportActivity}
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
      >
        {(value) => {
          const {
            dataAllLocations,
            dataCounter,
            dataStandByPerMonthly,
            dataAlertPerMonthly,
          } = value;
          return (
            <ReportActivity
              dataCounter={dataCounter.data}
              dataAllLocation={dataAllLocations.data}
              dataStandBy={dataStandByPerMonthly}
              dataAlert={dataAlertPerMonthly}
            />
          );
        }}
      </Await>
    </Suspense>
  );
}
