import { SkeletonDetailPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/Detail";
import DetailPatrolPoint from "@Organism/WorkLocation/PatrolPoint/Detail";
import { getDetailPatrolPoint } from "@Services/patrolPoint/getDetailPatrolPoint";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = ({ params }) => {
  const { patrolPointID } = params;
  const fetchDataDetailPatrolPoint = getDetailPatrolPoint(patrolPointID);

  return defer({
    getDataDetailPatrolPoint: fetchDataDetailPatrolPoint,
  });
};

export default function DetailPatrolPointPage() {
  const { getDataDetailPatrolPoint } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailPatrolPoint />}>
      <Await
        errorElement={<h1>Error get data !</h1>}
        resolve={getDataDetailPatrolPoint}
      >
        {(data) => <DetailPatrolPoint data={data?.data} />}
      </Await>
    </Suspense>
  );
}
