import router from "@Navigators/index";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import "moment/locale/id";
import moment from "moment";
moment.locale("id");

function App() {
  return <RouterProvider
    future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}
    router={router} />;
}

export default App;
