import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import DetailReportActivity from "@Organism/Report/Activity/Detail";
import { SkeletonDetailReport } from "@Organism/Skeletons/Reports/Detail";
import { getReportDetails } from "@Services/report/getReportDetail";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const dataReportDetailActivityLoader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const { id: locationID } = route?.params;
  const date = params?.get("date") || "2024-11-11";
  const shift = params?.get("shift") || "P";
  const status = [];
  const employeesID = [];

  params.forEach((value, key) => {
    if (key === "status") {
      status.push(value);
    }
  });

  params.forEach((value, key) => {
    if (key === "employeeID") {
      employeesID.push(value);
    }
  });

  const dataDetailReportActivity = getReportDetails(
    "activities",
    page,
    limit,
    locationID,
    date,
    status,
    employeesID,
    shift
  );
  return defer({
    dataDetailReportActivity: dataDetailReportActivity,
  });
};

export default function DetailReportActivityPage() {
  const { dataDetailReportActivity } = useLoaderData();

  return (
    <Suspense fallback={<SkeletonDetailReport />}>
      <Await
        resolve={dataDetailReportActivity}
        errorElement={<AsyncErrorPage />}
      >
        {(value) => <DetailReportActivity data={value} />}
      </Await>
    </Suspense>
  );
}
