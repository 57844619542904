import { API } from "configs";

export function getDataSchedulePerYear(year, locationID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/workschedule/yearly?year=${year}&locationID=${locationID}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
