import { API } from "configs";

export function patrolHistory(
  page = 1,
  limit = 10,
  patrolID = "",
  date = "2024-10-17",
  searchQuery = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/locationreport/patrols/checkpoint?page=${page}&limit=${limit}&checkpointID=${patrolID}&date=${date}&search=${searchQuery} `,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
