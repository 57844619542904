import { getDataReportDownloadExcel } from "@Services/report/reportDownloadExcel";
import ExcelJS from "exceljs";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import moment from "moment";
export const downloadExcel = async ({
  type,
  month,
  date,
  startDate,
  endDate,
  year,
  locationID,
  downloadType,
}) => {
  try {
    const response = await getDataReportDownloadExcel({
      type: type,
      month,
      date,
      startDate,
      endDate,
      year,
      locationID,
      downloadType: downloadType,
      // default daily
    });
    if (!response.status) {
      throw new Error("Failed to fetch data");
    }
    const data = await response?.data;

    console.log(data,'ini')

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // 3. Tambahkan Header
    worksheet.columns = [
      { header: "NO", key: "id", width: 10 },
      { header: "Nama", key: "name", width: 20 },
      { header: "Tanggal", key: "date", width: 25 },
      { header: "Waktu", key: "time", width: 25 },
      { header: "Lokasi", key: "location", width: 20 },
      { header: "Judul", key: "title", width: 20 },
      { header: "Status", key: "status", width: 15 },
      { header: "Deskripsi", key: "description", width: 30 },
      { header: "Latitude", key: "latitude", width: 20 },
      { header: "Longitude", key: "longitude", width: 20 },
    ];

    // 4. Tambahkan Data
    data.forEach((item, idx) => {
      worksheet.addRow({
        id: idx + 1,
        name: item?.name,
        date: moment(item?.submittedAt).format("LL"),
        time: moment(item?.submittedAt).format("LTS") + " WIB",
        location: capitalizeEachWord(item?.guardpoint),
        title: capitalizeEachWord(item?.title),
        status: item?.status,
        description: item?.description,
        latitude: item?.latitude,
        longitude: item?.longitude,
      });
    });

    // 5. Styling Header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "#2C5364" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
    });

    // 6. Simpan file Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Laporan ${type}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the report:", error);
  }
};
