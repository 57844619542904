import { API } from "configs";

export function getMonthlyAttendance(page, limit, search, month, year) {
  return new Promise(async (resolve, reject) => {
    try {
      const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
      const response = await API.get(
        `/attendance/getMonthlyAttendancePerClient`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: {
            clientCode: CLIENT_KEY?.code,
            page,
            limit,
            search,
            month,
            year,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
