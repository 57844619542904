import React, { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Tab from "@Atom/Tab";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
export default function Tabs({
  listTabs = ["tabs 1", "tabs 2", "tabs 3"], // isWitchIcon to be [{name:"abc",icon:"arrow"}]
  setIsActive = () => {},
  isActive,
  style,
  isV2 = false,
  isWithIcon = false,
  options = [],
  listMenu = [
    "Data Pribadi",
    "Data Keluarga",
    "Data Pendidikan",
    "Data Pekerjaan",
    "Data Prestasi",
  ],
  setActiveMenu = () => {},
  activeMenu,
}) {
  const handleActiveTabs = (idx) => {
    setIsActive(idx);
  };

  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    setOpenMenu(false);
  }, [isActive]);

  const openedMenuRef = useRef(null);
  const boxRef = useOutsideClick(openedMenuRef);
  useEffect(() => {
    if (boxRef && openMenu !== false) {
      setOpenMenu(false);
    }
  }, [boxRef, openMenu]);

  if (isV2) {
    return (
      <div className={Styles.container}>
        {options?.map((o, oI) => (
          <Tab key={oI} data={o} />
        ))}
      </div>
    );
  }

  return (
    <>
      {isWithIcon ? (
        <div className={Styles.wrapperTabs} style={style}>
          {listTabs?.map((item, idx) => (
            <div
              key={idx}
              className={Styles.wrapper}
              isactive={(isActive === idx)?.toString()}
              onClick={() => handleActiveTabs(idx)}
            >
              {item?.icon ? (
                <div className={Styles.wrapIcon}>
                  <h2>{item?.name}</h2>
                  <Icon
                    icon={item?.icon}
                    onClick={
                      openMenu === idx
                        ? () => setOpenMenu(false)
                        : () => setOpenMenu(idx)
                    }
                    color={"#2C5364"}
                    opened={(openMenu === idx).toString()}
                    size={22}
                    className={Styles.icon}
                  />
                  {openMenu === idx && (
                    <div ref={openedMenuRef} className={Styles.absoluteMenu}>
                      {listMenu.map((l, i) => (
                        <span
                          isactive={(activeMenu === i).toString()}
                          onClick={() => {
                            setActiveMenu(i);
                            setOpenMenu(false);
                          }}
                          className={Styles.list}
                          key={i + "-menu-list"}
                        >
                          {l}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <h2>{item?.name}</h2>
              )}
              <span
                className={Styles.tabIndicator}
                isaddicon={(isWithIcon && item.icon)?.toString()}
              ></span>
            </div>
          ))}
        </div>
      ) : (
        <div className={Styles.wrapperTabs} style={style}>
          {listTabs?.map((item, idx) => (
            <div
              key={idx}
              className={Styles.wrapper}
              isactive={(isActive === idx)?.toString()}
              onClick={() => handleActiveTabs(idx)}
            >
              <h2>{item}</h2>
              <span className={Styles.tabIndicator}></span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
