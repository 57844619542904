import React from "react";
import style from "./index.module.scss";
export default function Modal({
  children,
  isOpen,
  onClose = () => { },
  bg = "00000080",
  isTransparent = false,
  isSlider = false
}) {
  if (isSlider) {
    return (
      <div className={`${style.slider} ${style[isOpen ? 'show' : '']}`}>
        {children}
        <div className={`${style.overlay} ${style[isTransparent ? 'transparent' : '']}`} onClick={onClose} />
      </div>
    )
  }
  return (
    // <div>
    isOpen && (
      <div className={`${style.background}`}>
        {children}
        <div
          className={style.outside}
          style={{ background: bg }}
          onClick={onClose}
        />
      </div>
    )

    // </div>
  );
}
