import { SkeletonListGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/List";
import ListGuardPos from "@Organism/WorkLocation/GuardPos/List";
import { getAllGuardPost } from "@Services/guardPost/getAllGuardPost";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const searchQuery = params?.get("search") || "";

  const { id } = route?.params;
  const fetchAllGuardPost = getAllGuardPost(page, limit, id, searchQuery);

  return defer({
    getData: fetchAllGuardPost,
  });
};

export default function ListGuardPosPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListGuardPos />}>
      <Await resolve={getData} errorElement={<h1>Error get data !</h1>}>
        {(value) => <ListGuardPos data={value} />}
      </Await>
    </Suspense>
  );
}
