import { API } from "configs";

export function getAllPatrolPoint(
  page = 1,
  limit = 10,
  locationID = "",
  searchQuery = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/checkpoint-admin?page=${page}&limit=${limit}&locationID=${locationID}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
