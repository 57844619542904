import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import Button from "@Atom/Button";
import { fileBaseUrl } from "configs";
export default function ModalStatusReport({
  onClose = () => {},
  textHead = "Daftar Laporan Dengan Status Waspada",
  templateData = [
    {
      id: 1,
      name: "Anton Sinaga",
      profilePicture: "https://placehold.co/20x20",
      date: "Senin, September 12 2024",
      time: "14:23:46, WIB",
      shift: "Pagi",
      namePos: "Pos 1",
      statusLocation: "Waspada",
      reportDescription: "Lorem ipsum .",
      location: "Modena Gancy",
    },
  ],
  isTransition = false,
  isStandBy = false,
}) {
  const { width } = useWindowSize();
  const [showLocation, setShowLocation] = useState(false);

  const showDataModalActivity = useMemo(() => {
    return (
      templateData || [
        {
          id: 1,
          name: "Anton Sinaga",
          personil: "Nama Personil",
          titleIncident: "Pencurian Kabel Gudang",
          shift: "Pagi",
          activity: "Aktifitas 1",
          statusLocation: "Waspada",
          frontImage: "https://placehold.co/650x253/orange/blue",
          bottomImage: "https://placehold.co/650x253",
          rightImage: "https://placehold.co/650x253",
          leftImage: "https://placehold.co/650x253",
          reportDescription:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio doloremque neque quam mollitia cum fuga et labore doloribus nobis aperiam adipisci laudantium, nesciunt autem eius saepe, quos voluptas dolorem! Illo.",
        },
      ]
    );
  }, [templateData]);

  return (
    <Modal isOpen>
      <div
        className={Styles.ModalStatusReport}
        is-open={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          <div>
            <Icon
              icon={isStandBy ? "Information" : "information-alert"}
              size={"20"}
              //   className={Styles.icon}
              onClick={onClose}
              color={isStandBy ? "#E78B00" : "#D42701"}
            />
            <span>{textHead}</span>
          </div>
          <Icon
            icon={"close-circle"}
            size={"14"}
            className={Styles.icon}
            onClick={onClose}
          />
        </div>
        <div className={Styles.mainContainModalStatus}>
          {!!showLocation ? (
            <>
              {showDataModalActivity
                ?.filter((dataToDetail) => dataToDetail.id === showLocation)
                ?.map((el, idx) => (
                  <div className={Styles.mainContentModal} key={idx}>
                    <Button
                      text="Kembali"
                      isAddButton
                      icon={"arrow-back"}
                      isLeftIcon
                      colorIcon={"#2C5364"}
                      style={{
                        background: "white",
                        color: "#2C5364",
                        padding: "4px 8px",
                      }}
                      onClick={() => setShowLocation(false)}
                    />
                    <div className={Styles.status}>
                      <span>Personil</span>
                      <p>{el?.name}</p>
                    </div>
                    {/* <div className={Styles.status}>
                    <span>Judul Kejadian</span>
                    <p>{el?.titleIncident}</p>
                  </div> */}
                    <div className={Styles.status}>
                      <span>Status Lokasi</span>
                      <p>{el?.statusLocation}</p>
                    </div>
                    <div className={Styles.status}>
                      <span>Deskripsi Laporan</span>
                      <p>{el?.reportDescription}</p>
                    </div>
                    {el?.images?.map((i, index) => (
                      <div className={Styles.status} key={index}>
                        <span>
                          Foto Lokasi Tampak{" "}
                          {index === 0
                            ? "Depan"
                            : idx === 1
                            ? "Belakang"
                            : index === 2
                            ? "Kanan"
                            : "Kiri"}
                        </span>
                        <img src={fileBaseUrl + i?.URI} alt="image-location" />
                      </div>
                    ))}
                  </div>
                ))}
            </>
          ) : (
            <div
              className={Styles.HistoryActivity}
              is-zoom-out={width > 1440 ? "true" : "false"}
            >
              {templateData?.map((el, idxF) => (
                <div
                  className={Styles.viewerDetail}
                  style={{
                    borderTop:
                      idxF >= 1 && templateData?.length >= 1
                        ? "1px solid #dee2e7"
                        : "unset",
                    borderBottom:
                      templateData.length === idxF + 1
                        ? "1px solid #dee2e7"
                        : "unset",
                  }}
                  key={idxF}
                >
                  <div className={Styles.viewLeft}>
                    <div className={Styles.imgProfile}>
                      <img src={el.profilePicture} alt="image-profile" />
                    </div>
                    <div className={Styles.names}>
                      <span>{el.name}</span>
                      <div className={Styles.activities}>
                        <div className={Styles.desc}>
                          <p>{el.date}</p> • <p>{el.time}</p> •<p>{el.shift}</p>
                          •<p>{el.activity}</p>
                        </div>
                      </div>
                      <div className={Styles.location}>
                        <Icon icon={"building"} color={"#2C5364"} size={"16"} />
                        <span>{el.location}</span>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.viewRight}>
                    <div
                      className={Styles.condition}
                      condition={el.statusLocation.toLowerCase()}
                    >
                      <span>{el.statusLocation}</span>
                    </div>
                    <Icon
                      icon={"maximize"}
                      onClick={() => setShowLocation(el?.id)}
                      className={Styles.iconMax}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
