import { API } from "configs";

export function downloadCustomAttendance(date, start = '', end = '', clientCode = '') {
  return new Promise(async (resolve, reject) => {
    const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const response = await API.get(
        `/attendance/download/monthly/${clientCode || CLIENT_KEY.code}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { date, type: 'Custom Date', start, end },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
export function downloadMonthlyAttendance(month, year, clientCode = '') {
  return new Promise(async (resolve, reject) => {
    const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const response = await API.get(
        `/attendance/download/monthly/${clientCode || CLIENT_KEY.code}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { month, year, type: 'Bulanan' },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

