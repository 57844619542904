import { API } from "configs";

export function getDetailSchemaAbsence(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await API.get(`/attendanceschema/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
