import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import ReportIncident from "@Organism/Report/Incident";
import { getReportCounter } from "@Services/report/getReportCounter";
import { getAllInfoWorkLocation } from "@Services/workLocation/getAllInfoWorkLocation";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const incidentReportLoader = (route) => {

  return defer({
    packageLocations: getAllInfoWorkLocation(1, 10),
    packageCounter: getReportCounter("PATROL"),
  });
};

export default function ReportIncidentPage() {
  const { packageLocations, packageCounter } = useLoaderData();

  return (
    <Suspense fallback={<ReportIncident isLoadingCounter isLoadingLocations />}>
      <Await resolve={packageCounter} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
        {(packageCounter) => (
          <Suspense
            fallback={
              <ReportIncident
                packageCounter={packageCounter?.data?.data}
                isLoadingLocations
              />
            }
          >
            <Await resolve={packageLocations} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
              {(packageLocations) => (
                <ReportIncident
                  packageLocations={packageLocations?.data}
                  packageCounter={packageCounter?.data?.data}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
