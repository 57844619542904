import ImagesNoShift from "@Assets/Images/empty-handler-no-shift.png";
import Styles from "./style.module.scss";
export const EmptyHandlerForFilter = ({
  textHeader = "Data Tidak Ditemukan",
  description = "Tidak ada data dengan kriteria filter tersebut",
}) => {
  return (
    <div className={Styles.wrapEmptyHandler}>
      <img src={ImagesNoShift} alt="image-empty" />
      <div>
        <strong>{textHeader}</strong>
        <span>{description}</span>
      </div>
    </div>
  );
};
