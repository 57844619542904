import { API } from "configs";

export function getOnDutyMembers(
  type = "ACTIVITY",
  locationID,
  date = "2024-11-11",
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/locationreport/onduty?locationID=${locationID}&date=${date}&type=${type}&search=${search}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
