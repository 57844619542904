import BigCalendar from '@Molecule/BigCalendar'
import Styles from './style.module.scss'
import { useEffect, useMemo, useState } from 'react';
import { get35DaysInMonth } from 'helpers/get35DaysInMonth';
import moment from 'moment';
import { typeAttendace } from 'helpers/typeAttendance';
import { isDateBetween } from 'helpers/isDateBetween';
import Icon from '@Atom/Icon';
import CustomDatePicker from '@Atom/CustomDatePicker';
import Images from '@Theme/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton, { BoxSkeleton, CircleSkeleton } from '@Atom/Skeleton';
import useQuery from '@Hooks/useQuery';

export default function AttendanceDetailsLayout({
  isSkeleton = false,
  packageAttendanceDetails
}) {
  const navigate = useNavigate()
  const query = useQuery()
  const { pathname } = useLocation()
  const data = useMemo(() => {
    return {
      name: packageAttendanceDetails?.employee?.name,
      position: packageAttendanceDetails?.employee?.position,
      isActive: true,
      attendanceData: packageAttendanceDetails?.dailyAttendance
    }
  }, [packageAttendanceDetails?.dailyAttendance, packageAttendanceDetails?.employee?.name, packageAttendanceDetails?.employee?.position])
  const defaultDate = useMemo(() => {
    return query?.get('date') || moment(new Date()).format('YYYY-MM-DD')
  }, [query])
  const [valueDate, setValueDate] = useState(defaultDate);

  const attendanceData = useMemo(() => {
    const allDays = get35DaysInMonth(new Date(valueDate))

    const resultAttend = allDays?.map((obj) => {
      const currentAttend = data?.attendanceData?.find((d) => d?.startTime ? isDateBetween(obj, d?.startTime, d?.endTime || d?.startTime) : moment(d?.date)?.format('YYYY-MM-DD') === moment(obj)?.format('YYYY-MM-DD'));
      if (currentAttend) {
        return {
          ...currentAttend,
          date: moment(obj)?.format('YYYY-MM-DD'),
          value: typeAttendace(currentAttend?.type || ''),
        };
      } else {
        return {
          date: moment(obj)?.format('YYYY-MM-DD'),
          value: '',
        };
      }
    });

    return resultAttend
  }, [data?.attendanceData, valueDate])

  useEffect(() => {
    if (defaultDate !== moment(valueDate)?.format('YYYY-MM-DD')) {
      navigate(`${pathname}?date=${moment(valueDate)?.format('YYYY-MM-DD')}`, { replace: true })
    }
  }, [defaultDate, navigate, pathname, valueDate])

  return (
    <div className={Styles.container}>
      {
        isSkeleton
          ?
          <Skeleton h='30px' w='300px' />
          :
          <div className={Styles.header}>
            <button onClick={() => navigate(-1)}>
              <Icon icon={'arrow-chevron-left'} size={20} />
            </button>
            <h3>Detail Absensi</h3>
            <span>-</span>
            <CustomDatePicker autoSave type="month-year" value={valueDate} setValue={setValueDate} right='0' className={Styles.customDatePickerStyle} iconSize={24} padding='8px 1px' />
          </div>
      }
      <div className={Styles.content}>
        <div className={Styles.top}>
          <div className={Styles.profile}>
            {
              isSkeleton
                ?
                <CircleSkeleton w='65px' h='65px' />
                :
                <img src={Images.AVA_DEFAULT} alt='' />
            }
            {
              isSkeleton
                ?
                <div className={Styles.details}>
                  <BoxSkeleton h='20px' w='150px' />
                  <Skeleton h='16px' w='120px' />
                  <Skeleton h='20px' w='84px' />
                </div>
                :
                <div className={Styles.details}>
                  <span>{data?.name}</span>
                  <p>{data?.position}</p>
                  <div className={`${Styles.status} ${Styles[data?.isActive ? 'active' : 'inactiveF']}`}>
                    {data?.isActive ? 'Aktif' : 'Tidak Aktif'}
                  </div>
                </div>
            }

          </div>
          {
            isSkeleton
              ?
              <Skeleton h='36px' w='150px' />
              :
              <div className={Styles.attendance}>
                <div>
                  <span>Total</span>
                  <p>Kehadiran</p>
                </div>

                <span>{attendanceData?.filter(obj => obj?.value === 'attend')?.length}</span>
              </div>
          }
        </div>
        {
          isSkeleton
            ?
            <Skeleton h='400px' />
            :
            <BigCalendar attendances={attendanceData} />
        }
      </div>
    </div>
  )
}