import { API } from "configs";

export function getDailyAttendance(
  page,
  limit,
  search,
  date,
  type = [],
  position = [],
  location = []
) {
  return new Promise(async (resolve, reject) => {
    try {
      const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
      const types = type?.map((s) => `&type[]=${s}`)?.join("");
      const positions = position.map((s) => `&position[]=${s}`).join("");
      const locations = location.map((s) => `&location[]=${s}`).join("");

      const response = await API.get(
        `/attendance/getDailyAttendancePerClient?${types}${positions}${locations}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { clientCode: CLIENT_KEY?.code, page, limit, search, date },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
