import { API } from "configs";

export function historyActivityGuardPost(
  page = 1,
  limit = 10,
  guardID = "",
  date = "2024-10-17",
  searchQuery = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/locationreport/activities/guardpoint?page=${page}&limit=${limit}&guardID=${guardID}&date=${date}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
