import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import ReportPatrol from "@Organism/Report/Patrol";
import { getReportCounter } from "@Services/report/getReportCounter";
import { getAllInfoWorkLocation } from "@Services/workLocation/getAllInfoWorkLocation";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const patrolReportLoader = (route) => {

  return defer({
    packageLocations: getAllInfoWorkLocation(1, 10),
    packageCounter: getReportCounter('PATROL')
  });
};

export default function ReportPatrolPage() {
  const { packageLocations, packageCounter } = useLoaderData();

  return (
    <Suspense fallback={<ReportPatrol isLoadingCounter isLoadingLocations />}>
      <Await resolve={packageCounter} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
        {(packageCounter) => (
          <Suspense
            fallback={
              <ReportPatrol
                packageCounter={packageCounter?.data?.data}
                isLoadingLocations
              />
            }
          >
            <Await resolve={packageLocations} errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}>
              {(packageLocations) => (
                <ReportPatrol
                  packageLocations={packageLocations?.data}
                  packageCounter={packageCounter?.data?.data}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
