import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import CustomDatePicker from '@Atom/CustomDatePicker'
import Icon from '@Atom/Icon'
import SearchBar from '@Atom/SearchBar'
import TabHeader from '@Atom/TabHeader'
import { useDebounce } from '@Hooks/useDebounce'
import useQuery from '@Hooks/useQuery'
import AttendanceFilterSlider from '@Molecule/_modal/AttendanceFilterSlider'
import Table from '@Molecule/Table'
import Tabs from '@Molecule/Tabs'
import Images from '@Theme/Images'
import { fileBaseUrl } from 'configs'
import { capitalizeEachWord } from 'helpers/capitalizeEachWord'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { downloadDailyAttendance } from '@Services/attendance/downloadDailyAttendance'
import ExcelJS from "exceljs";
import ButtonDownloadAttendance from './ButtonDownloadAttendance'
import useOutsideClick from '@Hooks/useOutsideClick'
import { getMonths } from '@Assets/data/month'

export default function AttendanceListLayout({
  isLoading = false,
  packageAttendanceList,
  packageLocations = []
}) {
  const query = useQuery()
  console.log(packageLocations, 'pkkk')
  const defaultTab = query?.get('tab') === 'monthly' ? 1 : 0
  const defaultSearch = query?.get('search') || ''
  const [activeTab, setActiveTab] = useState(defaultTab)
  const [search, setSearch] = useState(defaultSearch)
  const debouncedSearch = useDebounce(search, 300)
  const defaultDate = query?.get('date') || moment(new Date())
  const [date, setDate] = useState(defaultDate)
  const defaultLimit = query?.get('limit') ? +query?.get('limit') : 10
  const [limit, setLimit] = useState(10)
  const defaultPage = query?.get('page') ? +query?.get('page') : 1
  const [page, setPage] = useState(defaultPage)
  const [showFilter, setShowFilter] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);



  const defaultType = useMemo(() => {
    const queriedType = query.get('type') || ''
    if (!queriedType) return []
    return queriedType?.includes('-') ? queriedType?.split('-') : [queriedType]
  }, [query])

  const defaultPositions = useMemo(() => {
    const queriedPosition = query.get('position') || ''
    if (!queriedPosition) return []
    return queriedPosition?.includes('-') ? queriedPosition?.split('-') : [queriedPosition]
  }, [query])

  const defaultLocations = useMemo(() => {
    const queriedLocation = query.get('location') || ''
    if (!queriedLocation) return []
    return queriedLocation?.includes('-') ? queriedLocation?.split('-') : [queriedLocation]
  }, [query])

  const [type, setType] = useState(defaultType)
  const [position, setPosition] = useState(defaultPositions)
  const [location, setLocation] = useState(defaultLocations)
  useEffect(() => setType(defaultType), [defaultType])
  useEffect(() => setPosition(defaultPositions), [defaultPositions])
  useEffect(() => setLocation(defaultLocations), [defaultLocations])

  const [sort, setSort] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });


  const tabOptions = useMemo(() => {
    return [
      {
        title: 'Absensi Harian',
        value: 'daily'
      },
      {
        title: 'Absensi Bulanan',
        value: 'monthly'
      }
    ]
  }, [])

  const isValueDifferent = useMemo(() => {
    if (defaultSearch !== debouncedSearch
      || defaultPage !== page
      || defaultLimit !== limit
      || defaultTab !== activeTab
      || moment(defaultDate)?.format('YYYY-MM-DD') !== moment(date)?.format('YYYY-MM-DD')
    ) {
      return true
    }
    return false
  }, [activeTab, date, debouncedSearch, defaultDate, defaultLimit, defaultPage, defaultSearch, defaultTab, limit, page])

  useEffect(() => {
    if (isValueDifferent) {
      navigate(`${pathname}?tab=${tabOptions[activeTab]?.value}&page=${page}&limit=${limit}&search=${debouncedSearch}&date=${moment(date)?.format('YYYY-MM-DD')}&type=${defaultType?.join('-')}&position=${defaultPositions?.join('-')}&location=${defaultLocations?.join('-')}`, { replace: true })
    }
  }, [activeTab, date, debouncedSearch, defaultLocations, defaultPositions, defaultType, isValueDifferent, limit, navigate, page, pathname, tabOptions])


  const dailyTemplate = useMemo(() => {
    return {
      data: packageAttendanceList?.data?.map(obj => {
        return {
          name: obj?.name,
          employeeID: obj?.employeeID,
          profilePicture: obj?.profilePicture ? fileBaseUrl + obj?.profilePicture : Images.AVA_DEFAULT,
          timeIn: obj?.startTime ? new Date(obj?.startTime) : null,
          timeOut: obj?.endTime ? new Date(obj?.endTime) : null,
          totalHours: obj?.totalWorkHours || '-',
          attendance: obj?.type === 'PRESENT' ? 'Hadir' : 'Tidak Hadir',
          position: capitalizeEachWord(obj?.position),
          locations: [obj?.location],
          selfie: obj?.image ? fileBaseUrl + obj?.image : null
        }
      }),
      columns: [
        {
          name: 'name',
          title: (
            <TabHeader
              text="Nama Personil"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.profile}>
              <Avatar imgUrl={row?.profilePicture || Images.AVA_DEFAULT} size='24px' />
              <div className={Styles.name}>
                <span>{row?.name}</span>
                <p>{row?.employeeID}</p>
              </div>
            </div>
          )
        },
        {
          name: 'workingTime',
          title: (
            <TabHeader
              text="Jam Masuk & Pulang"
              column={"workingTime"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.time}>
              <div>
                <Icon icon={'clock-solid'} size={12} color={'#718198'} />
                <span>{row?.timeIn ? moment(row?.timeIn)?.format('HH:mm') : '--:--'}</span>
              </div>
              <div>
                <Icon icon={'clock-solid'} size={12} color={'#718198'} />
                <span>{row?.timeOut ? moment(row?.timeOut)?.format('HH:mm') : '--:--'}</span>
              </div>
            </div>
          )
        },
        {
          name: 'totalHours',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Total Jam Kerja"
              column={"totalHours"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.totalHours}>
              <Icon icon={'timer-start-solid'} size={16} color={'#718198'} />
              <span>{row?.totalHours}</span>
            </div>
          )
        },
        {
          name: 'attendance',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Kehadiran"
              column={"attendance"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.attendance}>
              {row?.attendance}
            </div>
          )
        },
        {
          name: 'position',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Jabatan"
              column={"position"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
        },
        {
          name: 'locations',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Lokasi"
              column={"locations"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.locations}>
              {row?.locations?.join(', ')}
            </div>
          )
        },
        {
          name: 'picture',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Foto"
              column={"selfie"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            row?.selfie
              ?
              <div className={Styles.selfie}>
                <img src={row?.selfie} alt='' />
              </div>
              :
              <div className={Styles.noSelfie}>
                <Icon icon={'no-picture'} size={24} />
                <span>Foto Tidak Tersedia</span>
              </div>

          )
        },

      ]
    }
  }, [packageAttendanceList?.data, sort])

  const monthlyTemplate = useMemo(() => {
    return {
      data: packageAttendanceList?.data?.map(obj => {
        return {
          name: obj?.name,
          employeeID: obj?.employeeID,
          profilePicture: obj?.profilePicture ? fileBaseUrl + obj?.profilePicture : Images.AVA_DEFAULT,
          workingDays: obj?.totalWorkDays || '0',
          attendances: obj?.present || '0',
          revisions: obj?.revision || '0',
          leaves: obj?.leave || '0',
          alphas: obj?.absent || '0',
          permits: obj?.permit || '0',
          lates: obj?.late || '0',
        }
      }),
      columns: [
        {
          name: 'name',
          title: (
            <TabHeader
              text="Nama Karyawan"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <div className={Styles.profile}>
              <Avatar imgUrl={row?.profilePicture || Images.AVA_DEFAULT} size='24px' />
              <div className={Styles.name}>
                <span>{row?.name}</span>
                <p>{row?.employeeID || '-'}</p>
              </div>
            </div>
          )
        },
        {
          name: 'workingDays',
          title: (
            <TabHeader
              text="Jml Hari Kerja"
              column={"workingDays"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'attendances',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Kehadiran"
              column={"attendances"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'revisions',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Revisi"
              column={"revisions"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'leaves',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Cuti"
              column={"leaves"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'alphas',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Absen"
              column={"alphas"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'permits',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Izin"
              column={"permits"}
              sort={sort}
              setSort={setSort}
              isSorted
              justifyContent='flex-end'
            />
          ),
          align: 'right',
        },
        {
          name: 'lates',
          // title: 'Total Jam Kerja',
          title: (
            <TabHeader
              text="Terlambat"
              column={"lates"}
              sort={sort}
              setSort={setSort}
              justifyContent='flex-end'
              isSorted
            />
          ),
          align: 'right',
        },


      ]
    }
  }, [packageAttendanceList?.data, sort])

  const handleDailyDownload = async () => {
    try {
      const response = await downloadDailyAttendance(
        moment(date).format('YYYY-MM-DD'),
      );

      const data = await response?.data?.data;


      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      // 3. Tambahkan Header
      worksheet.columns = [
        { header: "No", key: "id", width: 10 },
        { header: "Nama", key: "name", width: 20 },
        { header: "NRK", key: "employeeID", width: 20 },
        { header: "Jabatan", key: "position", width: 20 },
        { header: "Lokasi", key: "location", width: 20 },
        { header: "Image", key: "image", width: 20 },
        { header: "Jam Masuk", key: "startTime", width: 20 },
        { header: "Jam Keluar", key: "endTime", width: 20 },
        { header: "Total Jam Kerja", key: "totalWorkHours", width: 20 },
        { header: "Kehadiran", key: "type", width: 20 },
      ];

      // 4. Tambahkan Data
      data.forEach((item, idx) => {
        worksheet.addRow({
          id: idx + 1,
          ...item,
          image: item.image ? fileBaseUrl + item.image : '',
        });
      });

      // 5. Styling Header
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "#2C5364" },
        };
        cell.font = {
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
      });

      // 6. Simpan file Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Laporan Kehadiran Harian.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) { }
  }

  const refBox = useRef(null);
  const isOutsideClick = useOutsideClick(refBox);

  useEffect(() => {
    if (isOutsideClick && isOpenModalDownload) {
      setIsOpenModalDownload(false);
    }
  }, [isOpenModalDownload, isOutsideClick]);


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Absensi</h3>
        <Tabs
          listTabs={tabOptions?.map(obj => obj?.title)}
          isActive={activeTab}
          setIsActive={setActiveTab}
        />
        <div className={Styles.filter}>
          <div className={Styles.leftFilter}>
            <SearchBar
              value={search}
              setValue={setSearch}
              placeholder='Search'
            />
            {
              activeTab === 0
              &&
              <Button
                text='Filter'
                icon={'setting-4'}
                isOutlined
                onClick={() => setShowFilter(true)}
              />
            }


          </div>
          <div className={Styles.rightFilter}>
            <div style={{ position: 'relative' }} className={Styles.downloadButton}>
              <Button
                text='Download Report'
                icon={'download'}
                isOutlined
                iconPosition='left'

                isDisabled={isLoading}
                onClick={query?.get('tab') === 'monthly' ? () => setIsOpenModalDownload(true) : handleDailyDownload}
              />

              {isOpenModalDownload && (
                <ButtonDownloadAttendance
                  type={"events"}
                  date={moment(date).format('YYYY-MM-DD')}
                  month={getMonths('long')[moment(date).format("M") - 1]}
                  year={moment(date).format("YYYY")}
                  ref={refBox}
                  onClose={() => setIsOpenModalDownload(false)}
                />
              )}
            </div>



            <CustomDatePicker
              value={date}
              setValue={setDate}
              placeholder='Select Date'
              right='0'
              padding='12px'
              type={query?.get('tab') === 'monthly' ? 'month-year' : 'full-date'}
              autoSave
            />
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <Table
          data={activeTab === 0 ? dailyTemplate?.data : monthlyTemplate?.data}
          columns={activeTab === 0 ? dailyTemplate?.columns : monthlyTemplate?.columns}
          className={Styles.table}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          totalData={packageAttendanceList?.totalData}
          totalPage={packageAttendanceList?.totalPages}
          withPagination
          onClickRow={(row) => navigate(`${pathname}/${row?.employeeID}?date=${moment(date)?.format('YYYY-MM-DD')}`)}
          isLoading={isLoading || isValueDifferent}
        />
      </div>
      <AttendanceFilterSlider
        show={showFilter}
        onClose={() => setShowFilter(false)}
        types={type}
        setTypes={setType}
        positions={position}
        setPositions={setPosition}
        locations={location}
        setLocations={setLocation}
        onSave={() => {
          navigate(`${pathname}?tab=${tabOptions[activeTab]?.value}&page=${page}&limit=${limit}&search=${debouncedSearch}&date=${moment(date)?.format('YYYY-MM-DD')}&type=${type?.join('-')}&position=${position?.join('-')}&location=${location?.join('-')}`, { replace: true })
        }}
        locationList={packageLocations}
        onReset={() => navigate(`${pathname}`, { replace: true })}
      />
    </div>
  )
}