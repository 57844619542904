import moment from 'moment'
import Styles from './style.module.scss'
import { getTimeDifference } from 'helpers/getTimeDifference'
import Icon from '@Atom/Icon'
import { useState } from 'react'
import AttendanceDetailModal from '@Molecule/_modal/AttendanceDetailModal'

export default function BigCalendar({
  attendances
}) {
  const [selectedAttendance, setSelectedAttendance] = useState(null)

  return (
    <div className={Styles.calendar}>
      <div className={Styles.cHeader}>
        {
          ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
            ?.map((h, hI) => (
              <div key={hI}>{h}</div>
            ))
        }
      </div>
      <div className={Styles.container}>
        <div className={Styles.cWrapper}>
          {attendances
            ?.map((b, bI) => (
              <div key={bI}
                onClick={() => b?.value ? setSelectedAttendance(b) : () => { }}
                style={{ cursor: b?.value ? 'pointer' : '' }}
                className={`${(bI + 1) % 7 !== 0 ? Styles.withBr : ''} 
  ${bI < (attendances?.length > 35 ? 35: 28) ? Styles.withBb : ''}
  ${!b?.value ? Styles.empty : Styles[b?.value]}
  ${new Date(b?.date)?.getDay() === 6 || new Date(b?.date)?.getDay() === 0 ? Styles.weekend : ''}
  ${b?.date?.split('-')[1] !== attendances[15]?.date?.split('-')[1] ? Styles.disabled : ''}
  `}
              >
                <p>
                  <span>{b?.date?.split('-')[2] || '-'}</span>
                  {
                    new Date(b?.date)?.getDay() !== 6 && new Date(b?.date)?.getDay() !== 0
                    &&
                    <span>
                      {
                        [
                          {
                            title: 'Hadir',
                            value: 'attend'
                          },
                          {
                            title: 'Sakit',
                            value: 'sick'
                          },
                          {
                            title: 'Cuti',
                            value: 'leave'
                          },
                          {
                            title: 'Izin',
                            value: 'permit'
                          },
                          {
                            title: 'Absen',
                            value: 'absence'
                          },
                        ]
                          ?.find(obj => obj?.value === b?.value)?.title
                      }
                    </span>
                  }

                </p>
                {
                  b?.value !== 'absence'
                  &&
                  b?.value !== 'sick'
                  &&
                  b?.value !== 'leave'
                  &&
                  [
                    {
                      icon: 'clock',
                      value: `${b?.startTime ? moment(new Date())?.format('HH:mm') : ''}-${b?.endTime ? moment(new Date())?.format('HH:mm') : (b?.startTime ? '...' : '')}`
                    },
                    {
                      icon: 'timer-start',
                      value: b?.endTime && b?.startTime ?  `${getTimeDifference(new Date(b?.endTime), new Date(b?.startTime))}` : '-'
                    },
                  ]
                    ?.map((d, dI) => (
                      <div key={dI}>
                        <Icon icon={d?.icon} size={16} />
                        <p>{d?.value}</p>
                      </div>
                    ))
                }
              </div>
            ))
          }
        </div>


        <AttendanceDetailModal
          selectedAttendance={selectedAttendance}
          onClose={() => setSelectedAttendance(null)}
        />
      </div>
    </div>

  )
}